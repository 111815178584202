import React from 'react';
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ScrollTrigger from "gsap/ScrollTrigger";
// import { useLayoutEffect, useState } from 'react';
import styles from './Education.module.less';
// import Button from '@/components/Button.js';
// import {
//     DownOutlined,
//     UpOutlined
// } from '@ant-design/icons';

// 确保初始化 ScrollTrigger 插件
gsap.registerPlugin(ScrollTrigger);
function Education({ message, onReceiveData }) {
    const data = [{
        name: '华东理工大学',
        logo: require('@/assets/huali.jpg'),
        time: '2021 - 2024',
        major: '计算机科学与技术',
    }, {
        name: '黑龙江职业学院',
        logo: require('@/assets/heizhiyuan.jpg'),
        time: '2015 - 2017',
        major: '市场营销',
    }
    ]
    return (
        <div className={styles.body}>
            <div className={styles.title}>教育经历</div>
            <div className={styles.box} >
                {data.map((item, index) => (
                    <div key={index} className={styles.card}>
                        <div className={styles.time}>{item.time}</div>

                        <img className={styles.logo} src={item.logo} alt="" />
                        <div>
                            <div className={styles.name}>{item.name}</div>
                            <div className={styles.major}>{item.major}</div>
                        </div>


                    </div>
                ))}
            </div>
            {/* <div className={styles.spread} onClick={spread}>
                {isSpread ? <UpOutlined className={styles.icon} /> : <DownOutlined className={styles.icon} />}
                {isSpread ? '收起' : '查看全部'}
            </div> */}


        </div>


    );
}


export default Education;