
// import logo from '../../../assets/logo.svg'
// import { Link } from "react-router-dom"
import styles from './Header.module.less';
import { Anchor } from 'antd';
import { useLayoutEffect, useState } from 'react';
export default function Header() {
    const [direction, setDirection] = useState('vertical')
    const handleResize = () => {
        if (window.innerWidth <= 1280) {
            setDirection('horizontal')
        } else {
            setDirection('vertical')
        }
    }
    useLayoutEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return (
        <div className={styles.header}>
            {/* <img src={logo} className={styles.logo} alt="logo" /> */}
            <div className={styles.menu}>
                {/* <div className={styles.item}>首页</div>
                <div className={styles.item}><Link className={styles.link} to="/Brand">品牌</Link></div>
                <div className={styles.item}>关于我们</div> */}
                <Anchor
                    direction={direction}
                    className={styles.anchor}
                    items={[
                        {
                            key: 'Home',
                            href: '#Home',
                            title: '首页',
                        },
                        {
                            key: 'Personal',
                            href: '#Personal',
                            title: '简介',
                        },
                        {
                            key: 'Experience',
                            href: '#Experience',
                            title: '工作',
                        },
                        {
                            key: 'Project',
                            href: '#Project',
                            title: '项目',
                        },
                        {
                            key: 'Education',
                            href: '#Education',
                            title: '教育',
                        },
                        // {
                        //     key: 'part-4',
                        //     href: '#part-4',
                        //     title: '极光',
                        // },
                        // {
                        //     key: 'part-5',
                        //     href: '#part-5',
                        //     title: '弹窗',
                        // },
                        // {
                        //     key: 'part-6',
                        //     href: '#part-6',
                        //     title: '轮播',
                        // },
                    ]}
                />
            </div>
        </div>
    )
}