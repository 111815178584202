import React, { useEffect, useRef, useState } from "react"
import { SceneManager } from "./SceneManager"
import "./ChristmasScene.css"
import "./MobileAlert.css"
import icon from '@/assets/maozi.png'

// 移除原来的 MusicControl 组件，创建新的 Switch 组件
const Switch = ({ isOn, onToggle }) => (
    <div
        onClick={(e) => {
            e.stopPropagation();
            onToggle(e);
        }}
        onMouseDown={(e) => {
            e.stopPropagation();  // 阻止 mousedown 事件
            e.preventDefault();    // 阻止默认行为
        }}
        style={{
            display: 'inline-block',
            position: 'relative',
            width: '50px',
            height: '24px',
            backgroundColor: isOn ? '#D42426' : '#e9e9eb',
            borderRadius: '12px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            marginLeft: '10px',
            verticalAlign: 'middle',
            border: '2px solid #fff',
            boxShadow: '0 0 10px rgba(255,255,255,0.5)'
        }}
    >
        <div
            style={{
                position: 'absolute',
                top: '2px',
                left: isOn ? '28px' : '2px',
                width: '16px',
                height: '16px',
                backgroundColor: '#ffffff',
                borderRadius: '50%',
                transition: 'left 0.3s',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}
        />
    </div>
);

const MobileAlert = () => (
    <div className="mobile-alert-overlay">
        <div className="mobile-alert-content">
            <h2>🎅 圣诞老人提醒您 🎅</h2>
            <p style={{
                color: '#fff',
                fontSize: '1.2rem',
                lineHeight: '1.6',
                marginBottom: '15px'
            }}>
                为了给您带来最好的圣诞体验<br />
                请使用电脑访问此页面哦！
            </p>
            <div style={{ fontSize: '2rem', marginTop: '10px' }}>
                🎄 ⛄ 🎁
            </div>
        </div>
    </div>
)

const ChristmasScene = () => {
    const sceneRef = useRef(null)
    const blockerRef = useRef(null)
    const instructionsRef = useRef(null)
    const hintRef = useRef(null)
    const sceneManagerRef = useRef(null)
    const audioRef = useRef(null)
    const [isMobile, setIsMobile] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [userPaused, setUserPaused] = useState(false)
    const [musicEnabled, setMusicEnabled] = useState(true); // 新增音乐开关状态

    document.title = "圣诞快乐！";
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = icon; // 替换为你的Favicon路径
    document.head.appendChild(link);

    // 添加创建音频的辅助函数
    const createAudio = () => {
        const audio = new Audio('Jingle Bells - Children\'s Christmas Favorites.mp3');
        audio.loop = true;
        audio.volume = 0.5;
        return audio;
    };

    useEffect(() => {
        // 检测移动设备
        const checkMobile = () => {
            const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
            setIsMobile(isMobileDevice);
        };

        checkMobile();

        if (!sceneRef.current) return;

        // 初始化背景音乐
        audioRef.current = createAudio();

        // 初始化场景管理器
        sceneManagerRef.current = new SceneManager(sceneRef.current, {
            blocker: blockerRef.current,
            instructions: instructionsRef.current,
            hint: hintRef.current,
            onSceneStart: () => {
                if (musicEnabled && audioRef.current) {
                    audioRef.current.play().catch(console.log);
                }
            },
            onScenePause: () => {
                if (audioRef.current && isPlaying) {
                    audioRef.current.pause();
                }
            },
            onSceneResume: () => {
                if (musicEnabled && audioRef.current) {
                    audioRef.current.play().catch(console.log);
                }
            }
        });

        sceneManagerRef.current.init();

        return () => {
            if (sceneManagerRef.current) {
                sceneManagerRef.current.dispose();
            }
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
        };
    }, []); // 移除 musicEnabled 依赖

    // 处理音乐开关
    const handleMusicToggle = (e) => {
        e.stopPropagation();
        setMusicEnabled(prev => {
            const newState = !prev;
            if (newState) {
                // 开启音乐
                if (!audioRef.current) {
                    audioRef.current = createAudio();
                }
                if (sceneManagerRef.current?.isActive()) {
                    audioRef.current.play().then(() => {
                        setIsPlaying(true);
                    });
                }
            } else {
                // 关闭音乐
                if (audioRef.current) {
                    audioRef.current.pause();
                    setIsPlaying(false);
                }
            }
            return newState;
        });
    };

    // 如果是移动设备，显示圣诞风格提示
    if (isMobile) {
        return <MobileAlert />
    }

    return (
        <>
            <div id="scene" ref={sceneRef}>
                <div id="blocker" ref={blockerRef}>
                    <div
                        id="instructions"
                        ref={instructionsRef}
                        onClick={(e) => {
                            // 只有直接点击 instructions 区域才触发开始
                            if (e.target === e.currentTarget) {
                                sceneManagerRef.current?.lockPointer();
                            }
                        }}
                    >
                        <span style={{
                            fontSize: "40px",
                            color: '#D42426',
                            textShadow: '0 0 10px rgba(255,255,255,0.8)',
                            fontWeight: 'bold',
                            marginBottom: '20px',
                            display: 'block'
                        }}>
                            🎄 点击开始 🎄
                        </span>
                        <br />
                        <div style={{
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            padding: '20px',
                            borderRadius: '15px',
                            border: '2px solid rgba(255,255,255,0.3)',
                            marginBottom: '20px'
                        }}>
                            <div style={{ marginBottom: '10px' }}>
                                <span style={{ color: '#D42426' }}>🎮</span> 移动: WASD键
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <span style={{ color: '#D42426' }}>⭐</span> 跳跃: 空格
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <span style={{ color: '#D42426' }}>🎅</span> 视角: 鼠标
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <span style={{ color: '#D42426' }}>❄️</span> 雪球: E键
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px',
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            padding: '10px',
                            borderRadius: '10px',
                            border: '2px solid rgba(255,255,255,0.3)'
                        }}>
                            <span style={{ color: '#fff' }}>🎵 圣诞音乐 🎵</span>
                            <Switch isOn={musicEnabled} onToggle={handleMusicToggle} />
                        </div>
                    </div>
                </div>
                <div id="hint" ref={hintRef}>
                    <span>🎄 按 ESC 键退出 🎄</span>
                </div>
            </div>
        </>
    )
}

export default ChristmasScene
