// import logo from './logo.svg';
import './Home.less';
import Header from './components/Header.js';
import Canvas from './components/Canvas/Canvas.js';
import Personal from './components/Personal.js';
import Project from './components/Project.js';
import Experience from './components/Experience.js';
import Education from './components/Education.js';

// import AuroraBorealis from './components/other/AuroraBorealis.js';

// import Dialog from './components/other/Dialog.js';
// import EmblaCarousel from './components/other/Carousel/index.jsx';
// import Banner from './components/other/Banner.js';
// import Button from '../../components/Button.js';
// import MyComponent from './components/other/MyComponent.js';
// import MyComponent2 from './components/other/MyComponent2.js';
// import HoverBorder from './components/other/visualEffects/HoverBorder.js';

// import ViewTransition from './components/other/ViewTransition.js';

import { useEffect } from 'react';

// import "@/utils/drawStarts.js";
import StarCanvas from "@/utils/drawStars.js";
// import { throttle } from '@/utils/index.js'

function throttle(func, limit) {
    let inThrottle;
    return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limit);
        }
    };
}


// import { useRef, useState } from "react";
// import gsap from "gsap";
// import { useGSAP } from "@gsap/react";

export default function App() {
    // const container = useRef();

    // const { contextSafe } = useGSAP({ scope: container });
    // const onClickGood = contextSafe(() => {
    //     gsap.to(".good", { rotation: 180 });
    // });
    // const [message, setMessage] = useState(1)
    // const onSendMessage = () => {
    //     console.log('向子组件发送消息');
    //     setMessage(message + 1)
    // };
    // const handleData = (data) => {
    //     console.log('接收到子组件消息:' + data);
    // }

    const Star = new StarCanvas();

    // 使用节流函数包装 addStars 方法
    const throttledAddStars = throttle(function (e) {
        Star.addStars(e);
    }, 40); // 每100毫秒最多执行一次

    useEffect(() => {

        window.addEventListener("mousemove", throttledAddStars);
        // window.addEventListener("mousemove", (e) => { Star.addStars(e) });
        //改造上面这个监听不要触发太过频繁

        window.addEventListener('resize', (e) => { Star.resizeCanvas() });
        return () => {
            window.removeEventListener('mousemove', () => { Star.clear() });
            window.removeEventListener('resize', () => { Star.resizeCanvas() });
        };
    }, []);

    return (
        <div className="App">
            <Header></Header>
            <div className='Home-content'>

                <div id='Home'>
                    <Canvas />
                </div>
                <div id='Personal' className='Home-Personal'>
                    <Personal></Personal>
                </div>
                <div id="Experience">
                    {/* <Banner /> */}
                    <Experience></Experience>
                </div>
                <div id="Project">
                    <Project></Project>
                </div>
                <div id="Education">
                    <Education></Education>
                </div>
                {/* <div>
                    <HoverBorder></HoverBorder>
                </div> */}

                {/*   <div style={{ height: '1000px', backgroundColor: '#fff' }}>

                    <div ref={container}>
                        <button onClick={onClickGood} className="good">aaa</button>
                    </div>
                    <button onClick={onSendMessage} >向子组件发送消息</button>
                </div>
                <div>
                    <MyComponent message={message} onReceiveData={handleData}></MyComponent>
                </div>
                <div style={{ height: '1000px', backgroundColor: '#fff' }}>
                    <Button text='为了这口醋包的这顿饺子'></Button>
                </div>
                <div id='part-2' style={{ height: '1000px', backgroundColor: '#fff' }}>
                    <MyComponent2></MyComponent2>
                </div>
                <div id='part-3' style={{ height: '1000px', backgroundColor: '#fff' }}>
                    <div className='part3'>
                        <h1>part-2</h1>
                    </div>
                </div>
                <div id='part-4' style={{ height: '1000px', backgroundColor: '#000' }}>
                    <AuroraBorealis></AuroraBorealis>
                </div>
                <div id='part-5' style={{ height: '1000px', backgroundColor: '#fff' }}>
                    <Dialog></Dialog>
                </div>
                <div id='part-6' style={{ height: '1000px', backgroundColor: '#fff' }}>
                    <EmblaCarousel></EmblaCarousel>
                </div> */}
                {/* <ViewTransition></ViewTransition> */}
                <div className='footer'>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">黑ICP备2023004331号</a>
                </div>
                {/* <div>
                    footer
                </div> */}
            </div>
        </div >
    );
}

;
