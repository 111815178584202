import * as THREE from "three"
import { Controls } from "./Controls"
import { SceneObjects } from "./utils/SceneObjects"
import * as CANNON from 'cannon-es';

export class SceneManager {
    constructor(container, options) {
        this.container = container
        this.blocker = options.blocker
        this.instructions = options.instructions
        this.hint = options.hint
        this.onSceneStart = options.onSceneStart
        this.onScenePause = options.onScenePause
        this.onSceneResume = options.onSceneResume

        // 场景相关
        this.scene = null
        this.camera = null
        this.renderer = null
        this.controls = null
        this.raycaster = null

        // 物体集合
        this.objects = []

        // 粒子系统(雪)
        this.particleSystem = null
        this.particleCount = 15000
        this.particles = null

        // 移动控制
        this.moveForward = false
        this.moveBackward = false
        this.moveLeft = false
        this.moveRight = false
        this.canJump = false
        this.prevTime = performance.now()
        this.velocity = new THREE.Vector3()
        this.direction = new THREE.Vector3()

        // 动画相关
        this.clock = new THREE.Clock()

        // 添加物理世界
        this.world = new CANNON.World({
            gravity: new CANNON.Vec3(0, -9.82, 0)
        });

        // 添加雪球数组
        this.snowballs = [];
        this.snowballBodies = [];

        // 添加地面物理体引用
        this.groundBody = null;

        // 修改物理材质
        this.snowballPhysicsMaterial = new CANNON.Material({
            friction: 1.0,
            restitution: 0
        });

        // 完全重写雪球接触材质的属性
        this.snowballContactMaterial = new CANNON.ContactMaterial(
            this.snowballPhysicsMaterial,
            this.snowballPhysicsMaterial,
            {
                friction: 1.0,
                restitution: 0,
                contactEquationStiffness: 1e2,      // 显著降低刚度，使其更软
                contactEquationRelaxation: 50,      // 大幅增加松弛度
                frictionEquationStiffness: 1e2,     // 降低摩擦刚度
                collisionResponse: 0.1,             // 降低碰撞响应
                contactEquationRegularizationTime: 20,
                contactSkinSize: 1.0                // 增加允许的重叠量
            }
        );

        // 增加雪球半径
        this.snowballRadius = 1.0;  // 稍微增大雪球尺寸

        // 绑定方法
        this.animate = this.animate.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
    }

    init() {
        this.setupCamera()
        this.setupScene()
        this.setupRenderer()
        this.setupControls()
        this.setupLights()
        this.createEnvironment()
        this.handleWindowResize()
        this.setupEventListeners()

        // 配置物理世界
        this.world.gravity.set(0, -20, 0)
        this.world.broadphase = new CANNON.NaiveBroadphase()
        this.world.solver.iterations = 20
        this.world.allowSleep = true

        // 添加雪球之间的接触材质
        this.world.addContactMaterial(this.snowballContactMaterial)

        // 修改地面接触材质
        const groundSnowContact = new CANNON.ContactMaterial(
            this.groundBody.material,
            this.snowballPhysicsMaterial,
            {
                friction: 1.0,
                restitution: 0.01,
                contactEquationStiffness: 1e8,
                contactEquationRelaxation: 3,
                frictionEquationStiffness: 1e8,
                frictionEquationRegularizationTime: 3
            }
        )
        this.world.addContactMaterial(groundSnowContact)

        // 添加键盘事件监听
        window.addEventListener('keydown', (e) => {
            if (e.key.toLowerCase() === 'e') {
                this.throwSnowball()
            }
        })

        // 移动点击事件监听器到这里，确保 controls 已初始化
        if (this.blocker && this.controls) {
            this.blocker.addEventListener('click', () => {
                if (this.controls) {
                    this.controls.lock()
                    // 调用场景开始回调
                    if (this.onSceneStart) {
                        this.onSceneStart()
                    }
                }
            })
        }

        // 开始动画循环
        this.animate()
    }

    setupCamera() {
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000)
        this.camera.position.y = 10
        this.camera.rotation.y = 180.65
    }

    setupScene() {
        this.scene = new THREE.Scene()
        this.scene.fog = new THREE.Fog(0x242426, 20, 250)
        this.raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, -1, 0), 0, 10)
    }

    setupRenderer() {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true
        })
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.renderer.setClearColor(0x242426)
        this.renderer.toneMapping = THREE.LinearToneMapping
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
        this.container.appendChild(this.renderer.domElement)
    }

    setupControls() {
        this.controls = new Controls(this.camera, this.container)

        if (this.controls) {
            this.controls.addEventListener('lock', () => {
                if (this.instructions && this.blocker && this.hint) {
                    this.instructions.style.display = 'none'
                    this.blocker.style.display = 'none'
                    this.hint.style.display = 'block'
                }
            })

            this.controls.addEventListener('unlock', () => {
                if (this.instructions && this.blocker && this.hint) {
                    this.blocker.style.display = 'block'
                    this.instructions.style.display = ''
                    this.hint.style.display = 'none'
                }
            })

            this.scene.add(this.controls.getObject())
        }
    }

    setupLights() {
        // 环境光
        const ambientLight = new THREE.AmbientLight(0x222222)
        this.scene.add(ambientLight)

        // 半球光
        const hemiLight = new THREE.HemisphereLight(0xebf7fd, 0xebf7fd, 0.2)
        hemiLight.color.setRGB(0.75, 0.8, 0.95)
        hemiLight.position.set(0, 100, 0)
        this.scene.add(hemiLight)
    }

    async createEnvironment() {
        // 创建地面
        this.createGround()

        // 创建雪
        this.createSnow()

        // 创建森林
        this.createForest()

        // 创建篝火
        const campfire = SceneObjects.createCampfire()
        campfire.position.set(170, 0, 0)
        this.scene.add(campfire)

        // 创建雪人
        this.createSnowmen()

        // 创建圣诞文字
        try {
            const christmasText = await SceneObjects.createChristmasText()
            christmasText.position.set(150, 12, 100)  // 放在雪人前面
            this.scene.add(christmasText)
        } catch (error) {
            console.warn('Failed to load Christmas text:', error)
        }
    }

    createGround() {
        // 创建视觉效果的地面
        const geometry = new THREE.PlaneBufferGeometry(700, 600, 22, 12)
        const material = new THREE.MeshPhongMaterial({
            color: 0xffffff,
            shininess: 20, // 降低光泽度，使其更像雪
            roughness: 1,  // 增加粗糙度
            bumpScale: 0.045,
            emissive: 0xebf7fd,
            emissiveIntensity: 0.03
        })

        const plane = new THREE.Mesh(geometry, material)
        plane.rotation.x = Math.PI / -2
        plane.receiveShadow = true
        plane.position.y = -5

        this.scene.add(plane)

        // 创建物理地面
        const groundShape = new CANNON.Plane()
        this.groundBody = new CANNON.Body({
            mass: 0, // 质量为0使其保持静止
            shape: groundShape,
            material: new CANNON.Material({
                friction: 1.0,     // 增加地面摩擦系数
                restitution: 0.05  // 降低弹性
            })
        })

        // 旋转地面使其水平
        this.groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0)
        this.groundBody.position.set(0, -5, 0) // 与视觉地面位置对应
        this.world.addBody(this.groundBody)
    }

    createSnow() {
        const loader = new THREE.TextureLoader()
        loader.crossOrigin = ""

        const pMaterial = new THREE.PointsMaterial({
            color: 0xffffff,
            size: 1.5,
            map: loader.load("/snowflake2.png"),
            blending: THREE.AdditiveBlending,
            depthTest: false,
            transparent: true
        })

        this.particles = new THREE.Geometry()

        for (let i = 0; i < this.particleCount; i++) {
            const particle = new THREE.Vector3(
                Math.random() * 500 - 250,
                Math.random() * 500 - 250,
                Math.random() * 500 - 250
            )
            particle.velocity = { y: 0 }
            this.particles.vertices.push(particle)
        }

        this.particleSystem = new THREE.Points(this.particles, pMaterial)
        this.particleSystem.position.set(100, 100, 0)
        this.scene.add(this.particleSystem)
    }

    createForest() {
        const positions = this.getForestPositions()
        positions.forEach(pos => {
            const tree = SceneObjects.createTree()
            tree.position.set(pos.x, pos.y, pos.z)
            this.scene.add(tree)
            this.objects.push(tree)
        })
    }

    createSnowmen() {
        const positions = [
            { x: 220, y: 10, z: 0 },
            { x: 215, y: 7, z: 50 },
            { x: 210, y: 7, z: -45 }
        ]

        positions.forEach((pos, index) => {
            const snowman = SceneObjects.createSnowman(pos.x, pos.y, pos.z)
            if (index > 0) {
                const scale = 1 - index * 0.25
                snowman.scale.set(scale, scale, scale)
            }
            this.scene.add(snowman)
            this.objects.push(snowman)
        })
    }

    getForestPositions() {
        const numOfTrees = 4
        const positions = []

        // 右侧树线
        for (let i = 0; i <= numOfTrees; i++) {
            positions.push({ x: 100, y: 0, z: 40 * i + 40 })
        }

        // 右墙
        for (let i = 0; i <= numOfTrees + 1; i++) {
            positions.push({ x: 40 * i + 100, y: 0, z: 40 * numOfTrees + 40 })
        }

        // 右后墙
        for (let i = 0; i <= numOfTrees + 1; i++) {
            positions.push({ x: 40 * (numOfTrees + 1) + 100, y: 0, z: 40 * numOfTrees + 40 - (40 * i + 40) })
        }

        // 左侧树线
        for (let i = 0; i <= numOfTrees; i++) {
            positions.push({ x: 100, y: 0, z: -(40 * i + 40) })
        }

        // 左墙
        for (let i = 0; i <= numOfTrees + 1; i++) {
            positions.push({ x: 40 * i + 100, y: 0, z: -(40 * numOfTrees + 40) })
        }

        // 左后墙
        for (let i = 0; i <= numOfTrees; i++) {
            positions.push({ x: 40 * (numOfTrees + 1) + 100, y: 0, z: -(40 * numOfTrees + 40) + (40 * i + 40) })
        }

        // 入口处的树
        const entrancePositions = [
            { x: 80, y: 0, z: 40 }, { x: 60, y: 0, z: 40 },
            { x: 40, y: 0, z: 40 }, { x: 20, y: 0, z: 40 },
            { x: 0, y: 0, z: 40 }, { x: -20, y: 0, z: 40 },
            { x: 80, y: 0, z: -40 }, { x: 60, y: 0, z: -40 },
            { x: 40, y: 0, z: -40 }, { x: 20, y: 0, z: -40 },
            { x: 0, y: 0, z: -40 }, { x: -20, y: 0, z: -40 },
            { x: -40, y: 0, z: -40 }, { x: -40, y: 0, z: -20 },
            { x: -40, y: 0, z: 0 }, { x: -40, y: 0, z: 20 },
            { x: -40, y: 0, z: 40 }
        ]

        return [...positions, ...entrancePositions]
    }

    setupEventListeners() {
        window.addEventListener('resize', () => this.handleWindowResize(), false)
        document.addEventListener('keydown', this.onKeyDown, false)
        document.addEventListener('keyup', this.onKeyUp, false)
    }

    handleWindowResize() {
        if (this.camera && this.renderer) {
            this.camera.aspect = window.innerWidth / window.innerHeight
            this.camera.updateProjectionMatrix()
            this.renderer.setSize(window.innerWidth, window.innerHeight)
        }
    }

    onKeyDown(event) {
        switch (event.keyCode) {
            case 27: // ESC
                this.moveForward = false
                this.moveBackward = false
                this.moveLeft = false
                this.moveRight = false
                break
            case 38: // up
            case 87: // w
                this.moveForward = true
                break
            case 37: // left
            case 65: // a
                this.moveLeft = true
                break
            case 40: // down
            case 83: // s
                this.moveBackward = true
                break
            case 39: // right
            case 68: // d
                this.moveRight = true
                break
            case 32: // space
                if (this.canJump === true) this.velocity.y += 350
                this.canJump = false
                break
        }
    }

    onKeyUp(event) {
        switch (event.keyCode) {
            case 38: // up
            case 87: // w
                this.moveForward = false
                break
            case 37: // left
            case 65: // a
                this.moveLeft = false
                break
            case 40: // down
            case 83: // s
                this.moveBackward = false
                break
            case 39: // right
            case 68: // d
                this.moveRight = false
                break
        }
    }

    updateSnow() {
        let pCount = this.particleCount
        while (pCount--) {
            const particle = this.particles.vertices[pCount]
            if (particle.y < -200) {
                particle.y = 200
                particle.velocity.y = 0
            }
            particle.velocity.y -= Math.random() * 0.02
            particle.y += particle.velocity.y
        }
        this.particles.verticesNeedUpdate = true
    }

    animate() {
        requestAnimationFrame(this.animate)

        if (this.particleSystem) {
            this.updateSnow()
            this.particleSystem.rotation.y += 0.001
        }

        if (this.controls && this.controls.isLocked === true) {
            this.raycaster.ray.origin.copy(this.controls.getObject().position)
            this.raycaster.ray.origin.y -= 10

            const intersections = this.raycaster.intersectObjects(this.objects)
            const onObject = intersections.length > 0
            const time = performance.now()
            const delta = (time - this.prevTime) / 1000

            this.velocity.x -= this.velocity.x * 10.0 * delta
            this.velocity.z -= this.velocity.z * 10.0 * delta
            this.velocity.y -= 9.8 * 100.0 * delta

            this.direction.z = Number(this.moveForward) - Number(this.moveBackward)
            this.direction.x = Number(this.moveRight) - Number(this.moveLeft)
            this.direction.normalize()

            if (this.moveForward || this.moveBackward) this.velocity.z -= this.direction.z * 400.0 * delta
            if (this.moveLeft || this.moveRight) this.velocity.x -= this.direction.x * 400.0 * delta

            if (onObject === true) {
                this.velocity.y = Math.max(0, this.velocity.y)
                this.canJump = true
            }

            this.controls.moveRight(-this.velocity.x * delta)
            this.controls.moveForward(-this.velocity.z * delta)
            this.controls.getObject().position.y += this.velocity.y * delta

            if (this.controls.getObject().position.y < 10) {
                this.velocity.y = 0
                this.controls.getObject().position.y = 10
                this.canJump = true
            }

            this.prevTime = time
        }

        // 更新物理世界
        this.world.step(1 / 60);

        // 更新雪球位置
        for (let i = 0; i < this.snowballs.length; i++) {
            const snowball = this.snowballs[i];
            const body = this.snowballBodies[i];

            // 更新位置和旋转
            snowball.position.copy(body.position);
            snowball.quaternion.copy(body.quaternion);

            const isNearGround = body.position.y <= -4.3;

            if (isNearGround) {
                body.velocity.set(0, 0, 0);
                body.angularVelocity.set(0, 0, 0);
                if (body.position.y > -4.8) {
                    body.position.y = -4.8;
                }
                body.sleep();
            } else {
                // 检查与其他雪球的碰撞
                for (let j = 0; j < this.snowballBodies.length; j++) {
                    if (i !== j) {
                        const otherBody = this.snowballBodies[j];
                        const distance = body.position.distanceTo(otherBody.position);

                        // 增加吸附距离和接触距离
                        const attractionDistance = this.snowballRadius * 2.5;
                        const contactDistance = this.snowballRadius * 1.5;

                        if (distance < attractionDistance) {
                            // 计算吸附力
                            const direction = new CANNON.Vec3();
                            direction.copy(otherBody.position).vsub(body.position);
                            direction.normalize();

                            // 增加吸附力
                            const attractionStrength = Math.pow(1.0 - (distance / attractionDistance), 2);
                            const attractionForce = 5.0 * attractionStrength; // 增加吸附力

                            // 应用吸附力
                            const force = direction.scale(attractionForce);
                            body.force.vadd(force, body.force);
                            otherBody.force.vsub(force, otherBody.force);

                            if (distance < contactDistance) {
                                // 几乎完全停止运动
                                body.velocity.scale(0.01);
                                otherBody.velocity.scale(0.01);

                                // 停止旋转
                                body.angularVelocity.set(0, 0, 0);
                                otherBody.angularVelocity.set(0, 0, 0);

                                // 允许更多重叠
                                const pushForce = 0.1; // 减小推力
                                const overlap = contactDistance - distance;
                                direction.scale(pushForce * overlap);
                                body.position.vsub(direction, body.position);
                                otherBody.position.vadd(direction, otherBody.position);

                                // 更快地使雪球停止
                                if (body.velocity.length() < 0.01) {
                                    body.velocity.set(0, 0, 0);
                                    body.sleep();
                                }
                                if (otherBody.velocity.length() < 0.01) {
                                    otherBody.velocity.set(0, 0, 0);
                                    otherBody.sleep();
                                }
                            }
                        }
                    }
                }
            }
        }

        if (this.renderer && this.scene && this.camera) {
            this.renderer.render(this.scene, this.camera)
        }
    }

    dispose() {
        // 清理场景中的所有对象
        while (this.scene.children.length > 0) {
            const object = this.scene.children[0]
            this.scene.remove(object)

            if (object.geometry) object.geometry.dispose()
            if (object.material) {
                if (Array.isArray(object.material)) {
                    object.material.forEach(material => material.dispose())
                } else {
                    object.material.dispose()
                }
            }
        }

        // 清理渲染器
        if (this.renderer) {
            this.renderer.dispose()
            this.renderer.forceContextLoss()
            this.renderer.domElement.remove()
        }

        // 移除事件监听器
        window.removeEventListener('resize', () => this.handleWindowResize())
        document.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('keyup', this.onKeyUp)

        // 清理控制器
        if (this.controls) {
            this.controls.dispose()
        }

        // 清理引用
        this.scene = null
        this.camera = null
        this.renderer = null
        this.controls = null
        this.particles = null
        this.particleSystem = null
    }

    throwSnowball() {
        // 确保相机已经初始化
        if (!this.camera || !this.scene || !this.world) {
            console.warn('Camera, Scene or Physics World not initialized');
            return;
        }

        // 获取相机位置和方向
        const cameraObject = this.controls.getObject();
        if (!cameraObject) {
            console.warn('Camera controls not initialized');
            return;
        }

        // 创建雪球几何体
        const segments = 16;
        const snowballGeometry = new THREE.SphereGeometry(this.snowballRadius, segments, segments);
        const snowballVisualMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            roughness: 0.9,    // 增加粗糙度
            metalness: 0.05    // 降低金属度，使外观更像雪
        });
        const snowball = new THREE.Mesh(snowballGeometry, snowballVisualMaterial);

        // 获取相机方向
        const cameraDirection = new THREE.Vector3();
        this.camera.getWorldDirection(cameraDirection);

        // 设置投掷起始位置（从相机前方开始）
        const offset = 2; // 向前偏移距离
        const startPosition = cameraObject.position.clone().add(
            cameraDirection.clone().multiplyScalar(offset)
        );
        snowball.position.copy(startPosition);

        this.scene.add(snowball);
        this.snowballs.push(snowball);

        // 修改物理体属性
        const shape = new CANNON.Sphere(this.snowballRadius);
        const body = new CANNON.Body({
            mass: 0.3,              // 降低质量使其更容易堆积
            shape: shape,
            material: this.snowballPhysicsMaterial,
            position: new CANNON.Vec3(
                startPosition.x,
                startPosition.y,
                startPosition.z
            ),
            linearDamping: 0.4,     // 增加空气阻力
            angularDamping: 0.4,    // 增加旋转阻力
            allowSleep: true,
            sleepSpeedLimit: 0.05,  // 降低休眠速度阈值
            sleepTimeLimit: 0.1,
            fixedRotation: true,    // 禁止旋转，使堆积更稳定
            collisionResponse: 0.1   // 降低碰撞响应
        });

        // 计算投掷速度
        const throwingForce = 15; // 由于雪球变大，适当减小投掷力度
        const upwardAngle = Math.PI / 10; // 稍微降低抛射角度

        // 计算投掷方向（考虑向上的角度）
        const throwDirection = new THREE.Vector3(
            cameraDirection.x,
            cameraDirection.y + Math.sin(upwardAngle),
            cameraDirection.z
        ).normalize();

        // 设置初始速度
        const velocity = throwDirection.multiplyScalar(throwingForce);
        body.velocity.set(velocity.x, velocity.y, velocity.z);

        // 添加一些随机旋转
        body.angularVelocity.set(
            Math.random() * 2 - 1,
            Math.random() * 2 - 1,
            Math.random() * 2 - 1
        );

        this.world.addBody(body);
        this.snowballBodies.push(body);
    }

    // 添加一个方法来检查场景是否激活
    isActive() {
        return this.controls?.isLocked;
    }

    // 在锁定指针时调用 onSceneStart
    lockPointer() {
        if (this.controls) {
            this.controls.lock()
            this.onSceneStart?.()
        }
    }

    // 在解锁指针时调用 onScenePause
    unlockPointer() {
        if (this.controls) {
            this.controls.unlock()
            this.onScenePause?.()
        }
    }

    // 在重新锁定指针时调用 onSceneResume
    resumeScene() {
        if (this.controls) {
            this.controls.lock()
            this.onSceneResume?.()
        }
    }
} 