export default class SnowflakeCanvas {
    constructor() {
        this.arr = [];
        this.colors = ["rgba(255, 255, 255, 0.8)", "rgba(255, 255, 255, 0.6)", "rgba(255, 255, 255, 0.4)"];
        this.timeoutList = [];
        this.canvas = document.createElement('canvas');
        document.body.appendChild(this.canvas);
        this.ctx = this.canvas.getContext("2d");
        this.resizeCanvas();
        // 给画布css样式，固定定位，且阻止用户的鼠标事件
        this.canvas.style.cssText = `
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          pointer-events: none;
        `;
        // window.addEventListener('resize', () => this.resizeCanvas());
        // window.addEventListener('mousemove', (e) => this.addSnowflakes(e));

        // 加载雪花图片
        this.snowflakeImage = new Image();
        this.snowflakeImage.src = '/snowflake.png';
        this.snowflakeImage.onload = () => {
            console.log('Snowflake image loaded');
        };
    }

    resizeCanvas() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
    }

    // 封装绘制一个圆形雪花函数
    snowflake(x, y, r) {
        this.ctx.beginPath();
        this.ctx.arc(x, y, r, 0, Math.PI * 2);
        this.ctx.closePath();
    }

    draw() {
        for (let i = 0; i < this.arr.length; i++) {
            let temp = this.arr[i];
            if (temp.type === 'image' && this.snowflakeImage.complete) {
                // 绘制图片雪花
                const scale = temp.r / 10; // 假设图片原始大小为20x20像素
                this.ctx.drawImage(this.snowflakeImage, temp.x - 10 * scale, temp.y - 10 * scale, 20 * scale, 20 * scale);
            } else {
                // 绘制圆形雪花
                this.snowflake(temp.x, temp.y, temp.r);
                this.ctx.fillStyle = temp.color;
                this.ctx.fill();
            }
        }
    }

    update() {
        for (let i = 0; i < this.arr.length; i++) {
            let snowflake = this.arr[i];
            snowflake.y += snowflake.dy;
            snowflake.r -= 0.03; // 加快雪花缩小的速度
            if (snowflake.y > this.canvas.height || snowflake.r < 0) {
                this.arr.splice(i, 1);
                // i--; // Adjust index since we removed an element
            }
        }
    }

    addSnowflakes(e) {
        const isImage = Math.random() < 0.03; // 10%的概率生成图片雪花
        if (isImage) {
            this.arr.push({
                x: e.clientX + Math.random() * 10 - 5, // 添加一些随机偏移
                y: e.clientY + Math.random() * 10 - 5,
                r: Math.random() * 10 + 5, // 图片雪花稍微大一点
                dy: Math.random() * 1 + 1, // 增加雪花的下落速度
                type: 'image',
            });
        } else {
            this.arr.push({
                x: e.clientX + Math.random() * 10 - 5, // 添加一些随机偏移
                y: e.clientY + Math.random() * 10 - 5,
                r: Math.random() * 3 + 3,
                dy: Math.random() * 1 + 1, // 增加雪花的下落速度
                color: this.colors[Math.floor(Math.random() * this.colors.length)],
                type: 'circle',
            });
        }

        for (let index = 0; index < 200; index++) {
            if (index === 0 && this.timeoutList.length > 0) {
                this.timeoutList.forEach(timeout => clearTimeout(timeout));
            }
            this.timeoutList[index] = setTimeout(() => {
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.draw();
                this.update();
            }, index * 20);
        }
    }

    clear() {
        this.arr = [];
        this.timeoutList.forEach(timeoutName => {
            clearTimeout(timeoutName)
        });
        this.timeoutList = [];
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
}

// 初始化雪花效果
const snowflakeCanvas = new SnowflakeCanvas();






