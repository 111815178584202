import React, { useState } from 'react';
import './AnimationCard.css'

import { useEffect } from 'react';
import SnowCanvas from "@/utils/drawSnow.js";
import icon from '@/assets/maozi.png'
function throttle(func, limit) {
	let inThrottle;
	return function () {
		const args = arguments;
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => inThrottle = false, limit);
		}
	};
}
function CardComponent() {


	const Snow = new SnowCanvas();

	const throttledAddSnowflake = throttle(function (e) {
		Snow.addSnowflakes(e);
	}, 20); // 每100毫秒最多执行一次


	useEffect(() => {

		document.title = "圣诞快乐！";
		const link = document.createElement('link');
		link.rel = 'icon';
		link.href = icon; // 替换为你的Favicon路径
		document.head.appendChild(link);

		window.addEventListener("mousemove", throttledAddSnowflake);
		window.addEventListener('resize', (e) => { Snow.resizeCanvas() });
		return () => {
			document.head.removeChild(link);
			window.removeEventListener('mousemove', () => { Snow.clear() });
			window.removeEventListener('resize', () => { Snow.resizeCanvas() });
		};
	}, []);

	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	const handleNextPage = (event) => {
		event.stopPropagation();
		window.location.href = '/christmas'
	}

	return (
		<div className='AnimationCardContainer'>
			<div className={`card ${isOpen ? 'is-opened' : ''}`} onClick={handleClick}>
				<div className="card-page cart-page-front">
					<div className="card-page cart-page-outside"></div>
					<div className="card-page cart-page-inside">
						<span className="merry-christmas">
							<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="540.054px" height="551.88px"
								viewBox="0 0 540.054 551.88" enableBackground="new 0 0 540.054 551.88" xmlSpace="preserve">
								<path fill="#000"
									d="M408.424,259.126c-6.145,0.44-11.873,2.104-17.349,4.458c1.873-7.48,7.205-30.873-6.877-29.254
	c-1.13,0.129-1.724,0.823-1.892,1.648c-0.16,0.177-0.31,0.373-0.429,0.615c-3.015,6.094-5.718,14.199-9.76,19.695
	c-1.472,2-2.722,4.848-5.667,6c-7.667,3-1-20.667,0.403-26.008c1.336-5.086-5.09-5.446-9.21-5.564
	c-1.478-0.042-2.408,0.997-2.672,2.134c-1.771,4.952-4.872,14.68-4.664,22.817c-1.675,2.943-5.191,12.621-10.857,13.621
	c-8.242,1.454-2.563-16.957-2-20.334c1.333-8,2.628-11.805-5.333-15c-2.264-0.908-5.406-1.915-7.769-2.502
	c0.089-0.499,0.184-0.998,0.258-1.5c0.452-3.046,0.324-8.625-2.972-10.302c-3.246-1.651-7.683-1.042-9.018,2.881
	c-2.086,6.129,1.369,9.709,6.025,11.925c-1.409,5.57-3.866,10.902-6.185,16.131c-2.527,5.698-5.047,11.584-8.066,17.048
	c-0.503,0.889-1.105,1.706-1.777,2.474c-0.19,0.137-0.375,0.295-0.542,0.504c-0.147,0.184-0.301,0.334-0.453,0.499
	c-0.32-1.622-0.045-3.463,0.099-5.091c0.205-2.319,0.537-4.664,0.959-6.956c1.638-8.898,5.757-23.821-7.247-27.625
	c-2.769-1.015-5.481-2.27-8.029-3.789c0.729-3.351,1.066-6.749,0.714-10.099c-0.746-7.1-9.771-11.932-14.462-5.102
	c-4.469,6.505,1.533,12.914,8.042,17.405c-1.567,5.595-4.063,11.042-6.443,16.208c-2.243,4.867-11.502,24.063-17.053,12.175
	c-1.347-2.884-1.583-6.25-1.22-9.587c6.726-0.07,13.875-8.967,13.043-15.18c-0.25-1.867-1.442-3.323-2.996-4.121
	c-4.746-3.586-10.56-2.08-15.424,1.055c-5.327,3.432-8.329,8.822-8.991,15.068c-0.485,4.564,0.015,9.123,1.842,13.173
	c-0.671,2.181-1.46,4.302-2.526,6.266c-2.609,4.809-5.47-0.677-6.093-3.317c-0.732-3.105-3.745-21.835-4.826-27.99
	c-0.839-4.777-2.416-9.121-6.94-11.494c-10.193-5.347-16.953,14.511-20.619,34.844c-0.15-1.523-0.739-13.674-0.972-15.188
	c-0.754-4.909-1.971-10.466-5.743-13.968c-4.074-3.782-9.072,0.642-11.787,3.652c-0.854,0.947-1.633,1.957-2.358,3.01
	c1.254-6.39,2.11-12.837,1.72-19.314c-0.468-7.769-12.292-8.248-17.77-6.557c-0.584,0.182-0.993,0.496-1.252,0.881
	c-0.352,0.365-0.592,0.87-0.618,1.536c-0.461,11.669-2.083,23.257-2.582,34.933c-0.246,5.756-0.359,11.518-0.576,17.275
	c-0.173,4.633-1.224,9.381-0.674,14.004c-0.065,0.294-0.071,0.599,0.018,0.902c0.062,0.21,0.139,0.41,0.224,0.605
	c0.016,0.078,0.027,0.156,0.044,0.234c0.207,0.955,0.807,1.531,1.521,1.787c15.66,5.629,13.604-13.445,16.139-23.459
	c1.607-6.35,3.581-13.52,7.333-19c2.295-3.353,4.394-5.369,5.333-1c0.722,3.357,1.002,10.45,1.042,13.863
	c0.079,6.824,0.312,13.729,1.365,20.477c0.133,0.851,7.386,6.916,12.593,0.66c1.244-1.494,1.333-10,3.088-19.338
	c0.628-3.344,1.549-6.59,2.556-9.836c0.305-0.986,3.55-9.936,5-7.762c1.556,2.336,1.679,18.956,2.082,23.988
	c0.771,9.63,5.483,19.66,17.274,16.613c5.786-1.496,9.039-6.484,11.333-12.666c5.667,6.666,14.002,5.833,19.671,2.529
	c0.402-0.234,0.683-0.529,0.88-0.852c2.013-1.537,3.779-3.596,5.123-5.467c4.225-5.885,7.139-12.777,9.854-19.457
	c0.973-2.395,1.935-4.897,2.751-7.459c0.509,0.287,1.002,0.558,1.482,0.814c0.128,0.186,0.275,0.365,0.468,0.531
	c2.325,1.996,1.456,5.473,0.881,7.986c-0.889,3.889-1.482,7.822-1.986,11.775c-0.613,4.807-1.573,9.453-1.231,14.329
	c0.734,10.451,12.601,13.38,19.37,6.712c2.306-1.92,3.896-4.813,5.129-7.449c4.693-10.033,10.295-20.252,13.13-31.088
	c0.216,0.063,0.431,0.124,0.646,0.184c0.032,0.029,0.066,0.051,0.088,0.09c1.744,3.153-0.742,13.437-1.256,16.153
	c-2.333,12.333-3,18.667,3.667,22.333c9.042,4.973,16.05,0.096,21.877-7.192c1.284-1.417,2.431-3.014,3.48-4.706
	c1.257,2.712,3.392,4.752,6.783,5.612c7.371,1.869,13.013-2.406,17.407-8.41c-1.083,4.436-2.145,8.874-2.98,13.362
	c-0.004,0.021-0.008,0.043-0.012,0.064c-6.87,4.783-14.099,11.954-13.435,20.629c0.138,1.807,1.163,3.113,2.544,3.811
	c6.372,6.195,14.074,1.231,18.952-5.764c4.638-6.649,6.664-14.654,8.515-22.522c6.12-3.015,12.377-5.245,19.508-5.757
	C412.306,263.852,411.632,258.896,408.424,259.126z M263.93,237.873c-0.803,1.768-3.477,4.858-5.943,5.6
	c0.358-1.313,0.771-2.576,1.212-3.75c0.547-1.458,1.317-2.783,2.211-4.049c0.785-1.5,1.993-1.813,3.625-0.939
	C264.817,235.837,264.449,236.883,263.93,237.873z M365.309,289.485c1.212-3.271,3.501-6.058,6.237-8.472
	c-0.521,2.279-1.136,4.535-1.949,6.755c-0.766,2.091-1.837,3.944-3.554,5.409c-0.506,0.432-0.859,0.606-1.102,0.609
	c-0.147-0.158-0.293-0.33-0.439-0.51C364.358,292.245,365.125,289.979,365.309,289.485z M279.171,220.98
	c-1.049-1.189-3.856-7.207,0.792-6.802c1.343,0.116,2.209,1.093,2.738,2.224c0.975,2.084,0.594,4.777,0.327,6.969
	c-0.046,0.374-0.103,0.747-0.16,1.12C281.549,223.412,280.298,222.256,279.171,220.98z M460.261,321.795
	c-2.297,0.051-4.698,0.373-7.054,0.967c-0.312-0.527-0.628-1.048-0.95-1.556c-2.765-4.362-7.715-7.108-8.257-11.988
	c1.271-0.249,2.456-0.715,3.303-1.604c1.382-1.451,1.335-3.506,0.256-5.125c-0.635-0.953-2.586-1.854-3.663-2.118
	c-4.707-1.161-8.634,4.292-9.429,8.232c-0.35,1.737-0.384,3.651-0.209,5.413c0.032,0.319,0.087,0.627,0.142,0.936
	c-1.194,4.824-2.818,9.509-5.416,13.795c-2.218,3.66-3.302,8.554-7.522,10.611c-3.069,1.498-5.07-0.545-5.334-3.6
	c-0.702-8.114,4.144-15.97,2.936-24.193c-0.539-3.666-3.685-6.91-7.546-7.244c-1.315-0.113-2.543,1.014-2.956,2.201
	c-0.372,1.07-0.735,2.167-1.083,3.283c-0.474-0.447-0.978-0.844-1.503-1.197c-4.403-4.775-12.259-5.602-17.941-2.453
	c-6.953,3.855-9.684,13.605-9.681,21.375c-1.202,1.977-2.692,4.322-4.335,6.551c-0.136,0.15-0.258,0.311-0.397,0.459
	c-1.443,1.554-3.092,2.843-4.926,3.898c-2.527,1.455-2.635-1.416-2.886-2.939c-1.141-6.952,1.666-13.807-1.528-20.525
	c-3.653-7.684-10.047-5.672-15.374-1.322c-2.936,2.396-5.522,5.318-7.486,8.565c-0.076-1.06-0.168-2.119-0.297-3.181
	c-0.617-5.066-4.673-8.176-9.651-7.184c-5.563,1.109-9.828,7.385-12.553,11.808c-0.41,0.665-0.798,1.343-1.175,2.024
	c0.194-5.033,0.603-10.133-0.059-15.098c-0.692-5.186-5.552-4.92-9.497-4.622c-1.083,0.081-1.706,0.7-1.946,1.472
	c-0.196,0.277-0.341,0.617-0.412,1.029c-0.679,3.979-1.224,7.951-1.658,11.936c-1.622,4.756-3.42,9.466-6.394,13.561
	c-0.307,0.422-0.634,0.824-0.974,1.207c-0.11,0.041-0.219,0.076-0.33,0.137c-2.94,1.57-4.662-6.824-4.906-8.22
	c-0.814-4.654,0.061-9.686,0.714-14.305c0.635-4.497,1.364-8.981,2.235-13.439c0.105-0.539,0.236-1.069,0.378-1.597
	c0.42,0.073,0.841,0.147,1.263,0.224c4.383,0.79,8.648,1.079,12.728-0.936c2.89-1.428,0.305-5.71-2.571-4.29
	c-3.016,1.49-6.515,0.974-9.912,0.287c0.517-1.566,0.985-3.147,1.208-4.81c0.075-0.56-0.023-1.02-0.229-1.392
	c-0.045-0.641-0.309-1.25-0.856-1.609c-2.438-1.6-4.834-2.195-7.724-2.332c-0.719-0.035-1.343,0.364-1.767,0.928
	c-0.203,0.178-0.392,0.387-0.549,0.657c-1.304,2.247-2.335,4.739-3.181,7.353c-2.33,0.153-4.645,0.521-6.942,1.184
	c-3.094,0.891-1.722,5.699,1.382,4.807c1.388-0.4,2.765-0.664,4.137-0.828c-1.098,4.696-1.791,9.529-2.483,13.885
	c-0.53,3.335-0.896,6.857-0.917,10.385c-3.426,1.811-6.832,3.984-10.031,6.457c-5.628-11.931-11.693-25.008,0.925-32
	c0.531-0.295,0.873-0.688,1.06-1.119c0.396-0.575,0.564-1.317,0.286-2.164c-1.721-5.236-7.724-6.742-12.485-5.035
	c-0.323,0.041-0.658,0.155-0.996,0.362c-0.113,0.068-0.215,0.145-0.326,0.214c-0.993,0.516-1.907,1.172-2.664,1.996
	c-5.104,4.49-7.203,10.863-6.915,17.957c0.065,1.615,0.319,3.197,0.69,4.758c-1.473,4.01-3.142,7.934-5.138,11.746
	c-1.854,3.541-4.122,8.018-7.381,10.782c-0.321,0.022-0.661,0.119-1.012,0.31c-4.804,2.611-3.137-11.627-2.837-13.236
	c0.785-4.214,2.301-8.338,2.565-12.637c0.4-6.508-6.321-8.827-11.453-6.123c-0.801,0.422-1.196,1.086-1.302,1.785
	c-0.022,0.056-0.051,0.103-0.07,0.16c-1.207,3.733-2.928,9.746-3.682,15.844c-0.948,2.313-1.948,4.604-3.07,6.848
	c-1.732,3.465-5,9.518-8.887,12.113c-0.366,0.063-0.741,0.222-1.109,0.53c-0.122,0.103-0.244,0.183-0.366,0.277
	c-0.289,0.118-0.58,0.217-0.873,0.288c-2.352,0.576,0.619-16.209,0.891-17.508c1.781-8.487-0.302-15.61-8.368-19.57
	c-0.847-0.416-1.717-0.775-2.599-1.105c0.453-4.509-0.037-8.852-2.241-12.449c-0.046-0.131-0.092-0.262-0.165-0.396
	c-2.191-4.104-7.075-5.24-11.055-2.865c-4.282,2.555-4.094,8.762-1.85,12.619c1.901,3.268,5.42,4.953,8.943,6.233
	c0.072,0.311,0.193,0.611,0.364,0.884c-0.454,1.828-0.976,3.596-1.413,5.209c-1.577,5.817-3.935,12.012-6.664,17.387
	c-1.665,3.279-3.677,6.613-6.358,9.158c-2.974,2.82-2.679-5.398-2.763-6.967c-0.274-5.111-0.797-13.539-7.785-12.937
	c-1.818,0.157-3.362,0.883-4.673,1.985c0.35-6.104,0.682-12.207,0.969-18.314c0.323-6.888,1.456-14.306-0.244-21.08
	c-1.645-6.557-7.007-7.266-12.705-7.285c-1.728-0.006-2.521,1.422-2.401,2.754c-0.068,0.234-0.118,0.482-0.118,0.758
	c-0.008,2.517-0.665,8.605-1.382,16.3c-4.275,13.324-8.808,26.704-16.4,38.53c-0.856,1.332-1.885,2.816-3.058,4.215
	c-0.221,0.132-0.436,0.291-0.631,0.51c-0.683,0.764-1.378,1.516-2.089,2.25c-2.423,2.022-5.27,3.242-8.447,2.338
	c-6.46-1.84-7.639-11.588-7.853-17.121c-0.59-15.274,5.032-50.82,27.228-45.957c1.721,0.378,2.999-0.893,3.273-2.221
	c0.129-0.471,0.099-1.01-0.179-1.593c-5.391-11.304-21.279-10.751-30.837-5.546c-12.958,7.057-16.732,23.674-17.865,37.146
	c-1.081,12.859-0.091,31.237,10.372,40.525c10.066,8.935,20.269,3.538,28.248-4.523c0.371-0.33,0.736-0.667,1.084-1.015
	c5.312-5.294,8.684-12.483,11.898-19.151c1.37-2.843,2.604-5.744,3.759-8.681c-1.094,21.095-0.058,42.746,10.608,39.853
	c0.612,0.042,1.254-0.122,1.836-0.602c1.856-1.529,2.811-3.461,3.047-5.876c0.423-4.313,0.841-8.667,1.106-12.994
	c0.029-0.474,0.055-0.948,0.084-1.422c0.744-2.872,2.04-5.552,4.186-7.634c4.016-3.896,3.906,5.043,4.03,7.344
	c0.278,5.185,1.018,14.533,8.667,11.76c7.027-2.548,10.771-14.33,13.25-20.424c1.593-3.917,3.619-8.845,4.956-13.94
	c1.566,3.384-1.042,11.239-1.647,13.267c-1.476,4.941-2.356,10.434-1.964,15.589c0.706,9.278,11.976,11.216,18.402,6.605
	c3.332-1.757,6.134-5.604,8.04-8.131c1.101-1.46,2.081-3.003,2.998-4.586c1.211,5.622,4.609,9.733,12.011,9.672
	c7.101-0.059,11.214-5.362,14.678-10.935c2.319-3.731,4.263-7.62,5.963-11.614c2.494,5.507,5.872,10.794,8.059,16.11
	c0.122,0.296,0.243,0.592,0.365,0.887c-2.071,2.531-3.853,5.227-5.233,8.051c-2.235,4.572-1.938,10.06,1.919,13.577
	c0.492,0.448,1.026,0.776,1.581,1.019c2.955,3.153,8.446,0.907,11.498-0.992c5.925-3.688,6.264-9.9,5.278-16.197
	c-0.465-2.975-1.808-6.379-3.436-9.988c2.62-2.053,5.406-3.893,8.191-5.485c0.415,3.625,1.309,7.151,2.914,10.368
	c3.135,6.28,11.169,8.172,16.498,3.572c1.966-1.697,3.571-3.658,4.938-5.781c-0.023,4.055,0.58,8.397,3.894,10.92
	c0.357,0.271,0.728,0.422,1.094,0.49c0.148,0.041,0.301,0.076,0.47,0.094c1.517,0.146,6.129-0.796,6.649-2.145
	c2.517-6.523,4.879-13.422,8.739-19.307c0.129-0.097,0.255-0.202,0.375-0.332c0.863-0.926,1.713-1.857,2.613-2.748
	c2.502-2.475,2.983,0.896,2.951,2.806c-0.054,3.146-0.345,6.255,0.466,9.348c1.156,4.401,3.6,10.899,9.298,9.868
	c0.047-0.008,0.087-0.026,0.133-0.037c2.893-0.604,2.249-2.187,2.593-5.282c1.062-7.313,2.738-14.266,7.951-19.983
	c2.406-2.638,3.516,1.379,3.458,3.277c-0.095,3.143,0.239,6.271,0.462,9.402c0.249,3.491,0.274,7.195,1.878,10.407
	c3.042,6.089,9.097,5.726,14.351,2.589c2.73-1.63,5.002-3.938,7.014-6.527c0.79-0.836,1.535-1.721,2.248-2.633
	c2.311,5.133,7.343,8.613,13.488,6.277c5.206-1.979,8.373-6.264,10.663-11.111c0.003,0.992,0.035,1.977,0.109,2.949
	c0.443,5.781,2.322,12.656,8.681,14.157c6.782,1.601,13.651-3.394,16.59-9.077c3.014-5.826,5.978-11.104,8.182-16.93
	c1.69,2.215,3.788,4.211,5.697,6.215c-1.943,1.607-3.578,3.559-4.705,5.9c-2.504,5.201-2.437,13.383,5.157,13.818
	c1.853,0.106,5.827-0.85,7.13-1.448c4.467-2.05,7.093-7.038,6.412-12.717c-0.242-2.015-0.845-3.987-1.645-5.888
	c1.505-0.325,2.975-0.504,4.309-0.533C463.024,326.706,463.48,321.725,460.261,321.795z M188.352,301.441
	c-0.942-0.343-1.874-0.711-2.781-1.141c-1.822-0.864-3.352-2.31-3.907-4.281c-0.647-2.295,1.561-5.789,3.488-4.93
	c0.107,0.214,0.24,0.426,0.432,0.63C188.093,294.385,188.643,297.85,188.352,301.441z M262.668,352.641
	c-0.679,2.059-2.257,2.356-4.736,0.896c-1.603-2.24-0.157-5.558,0.847-7.611c0.641-1.311,1.408-2.587,2.267-3.83
	c0.501,1.384,0.972,2.779,1.354,4.215C262.957,348.4,263.166,350.529,262.668,352.641z M399.04,324.032
	c-1.301,2.104-5.967,10.753-8.043,5.184c-1.247-3.347,0.451-8.728,1.844-11.843c1.66-3.712,5.99-6.674,9.446-5.381
	c1.393,0.712,1.355,1.988,1.422,2.088C402.629,317.688,400.541,321.604,399.04,324.032z M447.175,338.299
	c-0.397,3.41-5.654,5.101-5.223,0.588c0.29-3.033,1.956-5.486,4.283-7.371C447.3,333.583,447.464,335.817,447.175,338.299z
	 M223.894,292.253c-1.772-3.511-0.005-8.671,3.667-10.292c0.365-0.162,0.747-0.25,1.123-0.273c1.069-0.67,2.285-1.07,3.589-1.021
	c3.533,0.135,5.696,3.474,5.627,6.785c-0.071,3.384-1.722,6.66-5.083,7.813C229.756,296.314,225.461,295.355,223.894,292.253z
	 M308.336,425.396c-5.223-8.412-5.395-17.662-8.412-26.787c-1.359-4.113-4.332-9.742-8.426-12.928
	c1.357-0.906,2.586-2.051,3.658-3.121c1.939-1.938,5.084-5.66,2.957-8.486c-2.625-3.483-6.977-0.607-10.061,3.186
	c-0.195-3.039-1.205-6.039-2.945-8.348c-0.449-0.596-1.426-0.861-2.096-0.494c-3.602,1.968-4.486,5.473-3.988,9.096
	c-0.645-0.423-1.311-0.816-2.016-1.146c-2.406-1.127-6.156-1.715-8.18,0.41c-2.232,2.342-0.52,5.75,1.561,7.461
	c1.535,1.264,3.385,2.334,5.318,3.154c-3.342,3.002-6.027,7.102-7.779,10.5c-4.67,9.063-4.26,19.441-10.785,27.766
	c-0.432,0.549-0.467,1.106-0.27,1.564c-0.113,0.512,0.018,1.057,0.543,1.438c4.328,3.127,10.275,4.57,16.166,5.256
	c-0.072,2.406,1.021,4.623,3.855,5.092c2.941,0.488,5.65-1.881,6.91-4.469c4.621,0.059,8.988-0.834,13.396-2.129
	c2.826-0.832,6.23-1.631,8.572-3.602C307.777,428.998,309.396,427.104,308.336,425.396z M290.861,379.414
	c0.773-0.965,1.715-1.814,2.701-2.555c0.422-0.316,1.707-0.59,1.645-0.781c0.445,1.385-2.645,3.986-3.471,4.744
	c-0.98,0.9-2.387,2.168-3.813,2.837C288.561,382.207,290,380.488,290.861,379.414z M283.254,372.627
	c1.404,2.905,1.195,5.984,0.563,9.111C282.434,378.662,281.416,374.834,283.254,372.627z M271.51,379.096
	c1.439-0.957,4.23,0.541,5.492,1.246c1.6,0.896,2.699,2.324,3.809,3.738c-0.592,0.24-1.17,0.533-1.736,0.855
	C275.746,383.293,269.852,380.198,271.51,379.096z M282.422,428.195c-3.604,0.094-7.209-0.127-10.764-0.699
	c-2.049-0.33-4.004-1.096-5.963-1.793c1.998-2.264,3.27-5.561,4.246-8.104c3.371-8.783,1.41-19.32,9.004-26.148
	c11.117-9.994,14.707,11.941,15.469,18.465c0.658,5.637,1.75,10.769,4.873,14.936C293.908,426.943,288.521,428.035,282.422,428.195z
	 M321.808,144.121c-1.422,1.533-2.969,2.535-4.684,3.527c-1.709,0.988-8.504,4.634-9.122,0.725c-0.183-0.386-0.243-0.873-0.082-1.41
	c0.069-0.227,0.156-0.443,0.25-0.655c0.625-1.88,2.575-3.097,4.015-3.896l0,0c0.53-0.303,1.055-0.574,1.543-0.817
	c2.129-1.065,4.643-2.479,6.982-2.228C322.936,139.607,323.423,142.385,321.808,144.121z M328.172,172.662
	c-3.793,1.312-12.827,2.691-14.633-2.268c-0.752-2.069,0.467-3.036,2.34-3.361c4.24-1.541,9.16-0.662,12.784,2.05
	C330.049,170.12,329.875,172.072,328.172,172.662z M324.916,197.143c-1.335,2.791-5.577,0.688-7.417-0.281
	c-2.016-1.059-5.563-2.74-6.187-5.203c-0.203-0.801,0.03-1.658,0.644-2.126c0.238-0.397,0.643-0.692,1.215-0.726
	C316.851,188.586,327.475,191.793,324.916,197.143z M233.856,152.488c-0.237,0.131-0.514,0.209-0.835,0.191
	c-3.007-0.154-6.901-1.332-8.833-3.824c-1.316-1.701-2.986-4.377-1.227-6.336c2.125-2.367,7.309,0.695,9.703,2.277
	c0.1,0.059,0.205,0.119,0.303,0.178c1.395,0.83,4.222,2.334,4.225,4.27c0.008,0.033,0.025,0.065,0.031,0.1
	C237.565,151.362,235.669,152.52,233.856,152.488z M230.982,173.435c-1.393,1.3-3.721,1.729-5.535,1.979
	c-2.929,0.404-9.467,0.411-9.453-3.871c0.008-2.277,2.846-3.678,4.752-3.949c1.42-0.203,2.926-0.299,4.389-0.137
	c2.098-0.279,4.139,0.038,5.646,1.414C232.195,170.161,232.443,172.07,230.982,173.435z M234.063,191.785
	c-0.673,1.549-2.277,2.795-3.684,3.646c-2.131,1.293-7.573,3.701-8.916,0.16c-0.793-2.094,1.219-4.018,2.82-5.041
	c2.022-1.943,5.043-3.156,7.463-2.689C233.645,188.229,234.885,189.895,234.063,191.785z M296.14,189.635
	c-1.104,4.083-7.115,2.278-11.797-0.326c-0.054,0.566-0.196,1.088-0.486,1.482c-1.417,1.93-3.429,1.348-5.439,0.785
	c-1.392-0.389-2.955-1.035-4.287-1.937c-1.921,1.688-4.589,3.128-6.68,2.35c-1.295-0.481-2.037-1.654-2.309-3.003
	c-4.101,1.89-9.116,1.963-13.158,0.122c-0.898-0.409-1.146-1.304-0.686-2.151c0.67-1.236,1.87-2.507,3.237-3.609
	c-2.208-0.544-4.239-1.514-5.653-2.916c-0.543-0.538-0.559-1.465-0.008-2.006c1.861-1.824,4.303-2.32,6.713-3.072
	c1.243-0.389,2.343-1.06,3.408-1.803c-2.771-0.641-5.069-2.195-4.321-4.97c0.688-2.548,3.37-3.468,5.471-4.599
	c1.62-0.871,2.862-2.019,4.045-3.221c0.102-0.169,0.203-0.338,0.301-0.508c-1.67-0.475-3.289-1.338-4.72-2.131
	c-0.718-0.397-0.846-1.671-0.151-2.158c1.973-1.383,3.707-2.889,5.275-4.504c-0.562-0.574-0.789-1.381-0.392-2.521
	c0.668-1.92,2.366-3.146,3.849-4.43c1.78-1.54,2.971-3.471,4.544-5.175c0.269-0.292,0.606-0.386,0.933-0.347
	c0.707-0.57,2.092-0.457,2.498,0.701c1.061,3.03,3.752,5.49,5.982,7.695c1.026,1.015,2.254,2.354,1.722,3.914
	c-0.293,0.858-0.995,1.34-1.885,1.592c0.318,0.398,0.655,0.783,1.036,1.133c0.85,0.781,1.873,1.389,2.766,2.122
	c1.496,1.228,3.127,3.204,1.646,5.081c-0.693,0.879-1.709,1.33-2.878,1.475c1.904,0.926,4.104,1.422,6.026,2.4
	c2.042,1.043,3.669,3.069,1.584,5.077c-1.285,1.236-3.229,1.612-5.104,1.686c0.6,0.649,1.212,1.286,1.895,1.851
	c1.149,0.949,2.333,1.509,3.654,2.152c2.046,1,1.814,2.939,0.509,4.447c-0.993,1.146-2.275,1.703-3.674,1.875
	c0.343,0.256,0.683,0.511,1.011,0.768C292.313,184.283,296.874,186.922,296.14,189.635z M233.117,380.289c0,2.761-2.238,5-5,5
	s-5-2.239-5-5c0-2.762,2.238-5,5-5S233.117,377.527,233.117,380.289z M241.117,414.289c0,2.761-2.238,5-5,5s-5-2.239-5-5
	c0-2.762,2.238-5,5-5S241.117,411.527,241.117,414.289z M337.117,413.289c0,2.761-2.238,5-5,5s-5-2.239-5-5c0-2.762,2.238-5,5-5
	S337.117,410.527,337.117,413.289z M201.867,399.289c0,2.07-1.679,3.75-3.75,3.75s-3.75-1.68-3.75-3.75
	c0-2.071,1.679-3.75,3.75-3.75S201.867,397.218,201.867,399.289z M331.617,377.289c0,2.07-1.679,3.75-3.75,3.75
	s-3.75-1.68-3.75-3.75c0-2.071,1.679-3.75,3.75-3.75S331.617,375.218,331.617,377.289z M366.867,385.289
	c0,2.07-1.679,3.75-3.75,3.75s-3.75-1.68-3.75-3.75c0-2.071,1.679-3.75,3.75-3.75S366.867,383.218,366.867,385.289z" />
							</svg>
						</span>
					</div>
				</div>
				<div className="card-page cart-page-bottom">
					<div className='card-content'>
						<div className='card-text'>金禹彤同学圣诞快乐.
							<div className='card-author'>--初七</div>
						</div>
						<div onClick={handleNextPage}> <span className='card-page-next'>下一页</span> </div>
					</div>


				</div>
				<span className={`click-icon ${isOpen ? 'is-hidden' : ''}`} >
					<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
						<path fill="#fff"
							d="M31.6 17.7V26c0 1.9-.7 3.7-2 5.1v.9c0 1.6-1.3 3-3 3h-8.4c-1.6 0-3-1.3-3-3 0-.6.5-1 1-1s1 .4 1 1c0 .5.4 1 1 1h8.4c.5 0 1-.4 1-1v-1.2-.3-.1c0-.1.1-.2.2-.3 1.1-1.1 1.7-2.5 1.7-4v-8.3c0-.3-.1-.5-.3-.7-.1-.1-.5-.4-1-.3-.4.1-.8.6-.8 1.1v2.4c0 .6-.5 1-1 1s-1-.4-1-1v-5.5c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3c-.5 0-1 .5-1 1v5.5c0 .6-.5 1-1 1s-1-.4-1-1v-8.5c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3c-.5 0-1 .5-1 1v8.5c0 .6-.5 1-1 1s-1-.4-1-1V7.7c0-.3-.1-.5-.3-.7-.1-.1-.5-.4-1-.3-.4.1-.8.6-.8 1.1V20c0 .4-.2.8-.6.9-.4.2-.8.1-1.1-.2L11 18.1c-.6-.6-1.6-.6-2.2.1-.5.6-.4 1.5.2 2.1l7 7c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3-.3 0-.5-.1-.7-.3l-7-7.1c-1.3-1.3-1.5-3.5-.3-4.8C8 16 9 15.5 10 15.5c.9 0 1.8.4 2.5 1l.9.9V7.9c0-1.4.9-2.7 2.3-3 1-.3 2.1 0 2.8.8.6.6.9 1.3.9 2.1V9c.3-.1.7-.2 1-.2.8 0 1.5.3 2.1.9s.9 1.3.9 2.1v.2c.3-.1.7-.2 1-.2.8 0 1.5.3 2.1.9s.9 1.3.9 2.1v.2c.1 0 .2-.1.3-.1 1-.3 2.1 0 2.8.8.8.5 1.1 1.3 1.1 2z" />
					</svg>
				</span>
			</div>
		</div>
	);
}

export default CardComponent;