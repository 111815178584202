import React from 'react';
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useEffect, useState } from 'react';
import styles from './Project.module.less';
// import Button from '@/components/Button.js';
import { Image } from 'antd';

const data = [{
    id: 'fb',
    name: '收银系统',
    time: '2022.8-2024.9',
    images: [require('@/assets/fb/shouyin1.jpg'), require('@/assets/fb/shouyin2.jpg'), require('@/assets/fb/shouyin3.jpg'), require('@/assets/fb/shouyin4.jpg'), require('@/assets/fb/shouyin5.jpg')],
    videos: [],
    title: '前端架构',
    link: 'https://h5pos.itest.5foodbase.com/',
    content:
        `● 收银系统主要功能有收银，结账，桌台管理，优惠券，订单，会员，员工排班，财务，报表等，并配有厨房 KDS  划菜配菜等。
● 收银系统使用 uni-app 开发，发布了网页版，APP版，搭配electron发布了桌面exe版。
● 并配有副屏、广告屏、取餐叫号屏、厨房的 KDS (厨师制作)、划菜、配菜等子系统。`,
}, {
    name: '富得倍思综合后台管理系统',
    time: '2022.8-2024.9',
    images: [require('@/assets/fb/houtai1.jpg'), require('@/assets/fb/houtai2.jpg'), require('@/assets/fb/houtai3.jpg')],
    videos: [{
        cover: require('@/assets/fb/houtai1.jpg'),
        src: require('@/assets/fb/houtai.mp4'),
    }],
    title: '前端开发',
    link: 'https://smart-ai.itest.5foodbase.com/',
    content:
        `● 后台管理系统，采用 VUE3+TS+ElementPlus+webpack  作为主程序 使用 micro-zoe微前端连接多个子后台系统 VUE2版的拖拽低代码小程序设计系统等
● 主要功能：商品管理，优惠券，抽奖，价格方案，会员管理，分销，报表，vueFlow 流程设计，为小程序页面拖拽配置等等`,
}, {
    name: '香草花园小程序',
    time: '2022.8-2024.9',
    images: [require('@/assets/fb/weixin1.jpg'), require('@/assets/fb/weixin2.jpg'), require('@/assets/fb/weixin3.jpg'), require('@/assets/fb/weixin4.jpg'), require('@/assets/fb/weixin7.jpg')],
    videos: [],
    title: '小程序开发',
    content:
        `● 提供点餐 购券 充值 会员 分销等完整商城功能。
● 发布有VANILLE香草花园（上海），香草花园（兰州），甜心刻等多个小程序
● 使用uni-app开发，模块化组件使得可通过后台拖拽配置小程序页面`,
}, {
    name: '富贝云智慧管理小程序',
    time: '2023.2-2024.6',
    images: [require('@/assets/fb/wxMemange1.jpg'), require('@/assets/fb/wxMemange2.jpg'), require('@/assets/fb/wxMemange3.jpg')],
    videos: [],
    title: '小程序开发',
    content:
        `● 作为收银系统与后台管理的辅助系统，提供便捷查看销售情况、订单管理、数据分析、扫描打印等功能。
● 使用uni-app开发发布有小程序与安卓APP，也可在PDA终端运行`,
}, {
    name: 'AI聊天助理',
    time: '2023.7-2023.9',
    images: [require('@/assets/fb/aichat1.jpg'), require('@/assets/fb/aichat2.jpg'), require('@/assets/fb/aichat3.jpg')],
    videos: [],
    title: '前端架构',
    content:
        `● 使用vue+elementUi+wukongim做socket即时通讯聊天，使用AI大模型做后台对话，通过paddle飞浆支持语音/文字互转
● 响应式布局 大中小屏幕均适配，支持多主题嵌入3D对话人物glb模型在交互时有相应的动作`,
}, {
    name: '照片相似度对比小程序（个人项目）',
    time: '2023-4-2024-4',
    images: [require('@/assets/me/jietu1.jpg'), require('@/assets/me/ma1.jpg')],
    videos: [],
    title: '全栈开发',
    content:
        `● 用于对比两张照片的相似程度的小程序，提供了四种模式对比方法，基于离散余弦变换，颜色直方图，二值化，和使用基于TensorFlow的人像对比
● 前端使用uni-app 开发，后端使用node.js+koa.js+MongoDB开发，使用face-api在服务器端进行人脸对比
● 目前已经服务了2万+用户`,
}, {
    id: 'td',
    name: '智慧环卫大数据平台',
    time: '2022.2 -  2022.7',
    images: [require('@/assets/td/huanwei1.jpg'), require('@/assets/td/huanwei2.jpg'), require('@/assets/td/huanwei3.jpg'), require('@/assets/td/huanwei4.jpg'), require('@/assets/td/huanwei5.jpg')],
    videos: [],
    title: '前端开发',
    link: 'http://insight.huanwei.tidecloud.com/',
    content:
        `环卫 SaaS 产品
● 环卫工人佩戴电子工牌，通过系统为工人划定作业区从而有效管理工人的工作时间工作路线考勤等。监控环卫车辆路线垃圾点收运等。
● 主要功能人员管理、车辆管理、机械保洁、洒水作业、收运作业、作业绩效、公厕管理、实时监管、能耗管理、指挥调度
● 系统框架为 vue 全家桶+elementUI+axios+AMap+Echarts`,
}, {
    name: '智慧市政小程序',
    time: '2022.2 - 2022.7',
    images: [require('@/assets/td/xcx01.jpg'), require('@/assets/td/xcx2.jpg'), require('@/assets/td/xcx3.jpg')],
    videos: [],
    title: '小程序开发',
    link: '',
    content:
        `● vue 型网页版嵌套小程序（vue 全家桶+vant）
●主要功能有：设施查看、设施采集、巡更、作业云图、运营统计、油耗报表、人员绩效、告警提醒、垃圾收运、用车扫码、收运分析、收运任务等`,
}, {
    id: 'ws',
    name: '沃时派客官网',
    time: '2021.12 -  2022.1',
    images: [require('@/assets/ws/gw1.jpg'), require('@/assets/ws/gw2.jpg'), require('@/assets/ws/gw3.jpg')],
    videos: [],
    title: '前端开发',
    link: 'https://worthpack.com/#/home',
    content:
        `使用vue+vuex+vue-router开发，使用i18n做多语言`,
}, {
    name: '沃时包装助手小程序',
    time: '2021.3 -  2022.1',
    images: [require('@/assets/ws/xcx01.jpg'), require('@/assets/ws/xcx02.jpg'), require('@/assets/ws/xcx03.jpg'), require('@/assets/ws/xcx04.jpg')],
    videos: [],
    title: '小程序开发',
    link: 'https://worthpack.com/#/platform',
    content:
        `助力企业搭建实时的数据分析包装供应链系统，已打通从询价到送货全过程，为客户提供了产品询价、产品报价、采购订单、打样订单、质检报告、预约送货、收货验货等功能。全链路协同协同增加效率，减少人工成本，提高企业运营效率
● 功能有在线收货，预约送货，质检报告，查看打样单、采购单、报价单等
● 使用uni-app开发`,
}, {
    name: '沃时后台管理系统',
    time: '2021.3 -  2022.1',
    images: [require('@/assets/ws/ht1.jpg')],
    videos: [],
    title: '前端开发',
    link: 'https://c.worthpack.com/',
    content:
        `自主研发沃时云制造（MES系统）和沃时货运助手（TMS系统），打通了企业与生产工厂间的信息孤岛，过程数据更实时化，同时掌握生产、库存和物流信息，提升企业间供需要求反应速度。以确保在供应链风险加剧的情况下，仍能满足企业对于供应保障的需求
● 我负责前端业务开发，各个业务流程
● 使用vue+vueX+vueRouter+elementUi+webpack开发
● 为保证数据安全使用ase+rsa 混合加密
● 主要功能有询价、打样、销售、报价、入库等流程，产品管理，物料、工艺、质检等使用`,
}, {
    name: '沃时云制造app',
    time: '2021.5 -  2021.12',
    images: [require('@/assets/ws/app1.jpg')],
    videos: [],
    title: '前端架构',
    link: 'https://worthpack.com/#/platform',
    content:
        `● 在pad上运行的app
● 使用uni-app开发
● 主要功能是用来管理生产，生产端通过pad工单管理生产，订单等`,
}, {
    name: '沃时派客数据监控大屏看板',
    time: '2021.9 -  2021.9',
    images: [require('@/assets/ws/dp1.jpg')],
    videos: [],
    title: '前端架构',
    link: 'https://board.worhtpack.com/',
    content:
        `● 使用vue3+vite开发
● 含有echarts图表，echarts地图,百度地图，滚动列表，高德天气，视频监控等`,
}, {
    id: 'hy',
    name: '上海教育超市数据看板APP',
    time: '2019.12 - 2021.03',
    images: [],
    videos: [],
    title: '全栈开发',
    link: '',
    content:
        `● 提供查看各门店各区域日，月，年销售报表，销售分析，时段，分类以及具体的交易流水等查看。
● 项目使用uni-app开发，图表使用uCharts，接口使用.net C# 做WebService
● 负责整个项目搭建`,
}, {
    name: '佳思多食品料理 与 厦门珍煕便利店',
    time: '2017.8 - 2021.03',
    images: [],
    videos: [],
    title: '全栈开发',
    link: '',
    content:
        `两个项目近似 为全套便利店+餐饮系统，包括总部管理系统，门店管理系统，POS收银系统等，以及对接其他平台仓储、会员、线上商城等
● 涉及技术有：html,easyUi,jQuery,Echarts,C#,java,SqlServer,mySql,sqlLite
● 主要功能有：商品资料，公司门店，供应商，合同，货架，促销 ，价格管理，门店订货退货，销售情况，调拨，盘点，仓库订货，批发，进销存及各种报表等等
● 我在项目中主要负责总部管理系统，门店管理系统和对接用友财务系统及仓库等其他平台，用C#做Web Service接口，SqlServer存储过程
这是段很全面的项目经验，从前期调研需求，设计结构，驻场开发，测试，上线，后期维护，我都在其中，收获的经验不仅仅是技术，还有与客户的沟通，项目进度的管控等等。`,
}, {
    id: 'mj',
    name: '电商咖啡平台偷时',
    time: '2017.3 - 2017.6',
    images: [],
    videos: [],
    title: '前端开发',
    link: '',
    content:
        `● 该项目是专注于精品咖啡和咖啡文化的服务平台，整合各个咖啡店入住，用户使用App在线点单，帮助商家提高销售额 引流等。
● 项目中前端使用 bootstrap  jquery angular canvas 图表等技术。
● 负责前端 jsp页面，客户端分享页，与java后台交互。`,
}, {
    name: '壹媒介官网',
    time: '2017.4 - 2017.5',
    images: [],
    videos: [],
    title: '前端开发',
    link: 'https://www.onemedia.net.cn/',
    content:
        `● 负责完成前端项目的搭建，开发与测试并参与设计等。
● 在项目中主要完成了各个页面协调与展示，与后端同事对接
● 技术上采用Html ,CSS, bootstrap,JQuery，angular.js,fullPage,canvas等技术。`,
}, {
    name: 'TT通行道闸后台管理系统',
    time: '2016.7 - 2017.3',
    images: [],
    videos: [],
    title: '前端开发',
    link: '',
    content:
        `该项目主要以甲级写字楼和高端物业为中心，为其提供更安全的人员通行管理系统解决方案。提供进出闸机设备与系统软件。

项目分为三个后台系统
● 总后台管理系统：管理全项目所有信息，包括下属物业，公司，用户情况等。
● 物业后台管理系统：管理自己物业的下的用户情况卡，手机二维码，报修等。
● 公司后台管理系统：管理自己公司员工信息 卡，二维码等。

我在此项目负责前端页面，手机分享页等开发与整站的设计等，对接java后端。
主要使用技术 html，css, js, jquery等 . 使用了很多插件如：BMap.js , layDate 分页 弹出框 图片查看等
使用artTemplate.js 模板引擎做数据渲染`,
}]

// 确保初始化 ScrollTrigger 插件
gsap.registerPlugin(ScrollTrigger);


// 创建一个函数来初始化滚动触发的动画
const initScrollTriggerAnimation = (elementClass, animationProps) => {
    const instance = gsap.to(`.${elementClass}`, {
        ...animationProps,
        scrollTrigger: {
            trigger: `.${elementClass}`,
            start: 'top 90%',
            end: 'top 80%',
            // markers: true,
            // scrub: true,
        },
    });

    // 返回清理函数以便在组件卸载时使用
    return () => {
        if (instance.scrollTrigger) {
            instance.scrollTrigger.kill();
            instance.kill();
        }
    };
};
function Project({ message, onReceiveData }) {

    useLayoutEffect(() => {
        let cleanupFunctions = []
        cleanupFunctions = [
            // initScrollTriggerAnimation("projectCard", { opacity: "1", duration: 1 }),
        ];
        data.map((item, index) => cleanupFunctions.push(initScrollTriggerAnimation(`projectCard${index}`, { opacity: "1", duration: 1 })))

        return () => cleanupFunctions.forEach(fn => fn());
    }, []);


    // const [isSpread, setIsSpread] = useState(true)
    // const visibleItems = isSpread ? data : data.filter((_, index) => index <= 1);
    // function spread() {
    //     setIsSpread(!isSpread)
    // }
    const handleResize = () => {
        if (window.innerWidth <= 500) {
            setImgWH(50)
        } else {
            setImgWH(80)
        }
    };
    // let [height, setHeight] = useState(630)
    let [imgWH, setImgWH] = useState(80)

    useEffect(() => {
        // setHeight(0)
        // const element = document.querySelectorAll('.projectCard');
        // let totalHeight = 0;
        // element.forEach(item => {
        //     totalHeight += item.clientHeight + 20;
        // });
        // setHeight(totalHeight);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            // setHeight(630)
            window.removeEventListener('resize', handleResize);

        };
    }, []);

    return (
        <div className={styles.body}>
            <div className={styles.title}>项目经历</div>
            {/* <div className="trigger-element">This is the trigger element</div>
            <div ref={itemRef}>This element will fade in when scrolled into view</div> */}
            {/* style={{ height: isSpread ? height + 'px' : '630px' }} */}
            <div className={styles.box} >
                {data.map((item, index) => (
                    <div key={index} className={[styles.projectCard, 'projectCard', 'projectCard' + index].join(' ')} id={item.id}>
                        {/* <a name={item.id}></a> */}
                        <div className={styles.time}>{item.time}</div>
                        <div className={styles.right}>
                            <div className={styles.titleRow}>
                                <div className={styles.company}>{item.name}</div>
                                <div className={styles.title}>{item.title}</div>
                            </div>
                            <pre className={styles.content}>
                                {item.content}
                            </pre>
                            <div className={styles.btnBox}>
                                <div className={styles.link}>
                                    {item.link && (<a href={item.link} target="_blank" rel="noopener noreferrer">项目链接</a>)}
                                </div>
                                <div className={styles.imgBox}>
                                    <Image.PreviewGroup>
                                        {item.images.map((item, index) => (
                                            <Image key={index} width={imgWH} height={imgWH} className={styles.img} src={item} />
                                        ))}
                                    </Image.PreviewGroup>
                                    {item.videos.map((item, index) => (
                                        <div key={index} className={styles.videoBox}>
                                            <Image
                                                className={styles.img}
                                                width={imgWH}
                                                height={imgWH}
                                                preview={{
                                                    destroyOnClose: true,
                                                    imageRender: () => (
                                                        <video autoPlay muted width="100%" controls src={item.src} />
                                                    ),
                                                    toolbarRender: () => null,
                                                }}
                                                src={item.cover}
                                            />
                                            <span className={styles.tag}>视频</span>
                                        </div >
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* <div>
                        描述
                    </div> */}


                    </div>
                ))}
            </div>
            {/* <div className={[styles.spread, !isSpread && styles.spreadAnimation].join(' ')} onClick={spread}>
                {isSpread ? <UpOutlined className={styles.icon} /> : <DownOutlined className={styles.icon} />}
                {isSpread ? '收起' : '查看全部'}
            </div> */}



        </div >


    );
}

export default Project;