import React from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
// import { useLayoutEffect } from 'react';
import styles from './Personal.module.less';


// 注册ScrollTrigger插件
gsap.registerPlugin(ScrollTrigger);

// 创建一个函数来初始化滚动触发的动画
// const initScrollTriggerAnimation = (elementClass, animationProps) => {
//     const instance = gsap.to(`.${elementClass}`, {
//         ...animationProps,
//         scrollTrigger: {
//             trigger: `.${elementClass}`,
//             start: 'top 50%',
//             end: 'top 40%',
//             markers: true,
//             scrub: true,
//         },
//     });

//     // 返回清理函数以便在组件卸载时使用
//     return () => {
//         if (instance.scrollTrigger) {
//             instance.scrollTrigger.kill();
//             instance.kill();
//         }
//     };
// };

export default function Personal() {
    // useLayoutEffect(() => {
    //     let cleanupFunctions = []
    //     // 使用解构赋值来存储清理函数，确保在组件卸载时执行
    //     if (window.innerWidth > 500) {
    //         cleanupFunctions = [
    //             initScrollTriggerAnimation("test1", { left: "20%", duration: 1 }),
    //             initScrollTriggerAnimation("test2", { top: '70vh', left: '45%', duration: 1 }),
    //         ];
    //     }
    //     // 清理函数，当组件卸载时执行所有收集的清理操作
    //     return () => cleanupFunctions.forEach(fn => fn());
    // }, []); // 确保只在组件挂载和卸载时运行
    const photo = require('../../../assets/me.jpg');
    const ability = [
        {
            name: 'Photoshop',
            icon: require('../../../assets/ability/Photoshop.jpg')
        },
        {
            name: 'HTML',
            icon: require('../../../assets/ability/HTML5.jpg')
        },
        {
            name: 'CSS',
            icon: require('../../../assets/ability/CSS3.jpg'),
        },
        {
            name: 'JavaScript',
            icon: require('../../../assets/ability/javascript.jpg')
        },
        {
            name: 'Vue',
            icon: require('../../../assets/ability/vue.jpg')
        },
        {
            name: 'React',
            icon: require('../../../assets/ability/react.jpg')
        },
        {
            name: 'uniApp',
            icon: require('../../../assets/ability/uniApp.jpg')
        },
        {
            name: 'Node',
            icon: require('../../../assets/ability/node.jpg')
        },
        {
            name: 'Koa',
            icon: require('../../../assets/ability/koa.jpg')
        },
        {
            name: 'express',
            icon: require('../../../assets/ability/express.jpg')
        },
        {
            name: 'Electron',
            icon: require('../../../assets/ability/electron.jpg')
        },
        {
            name: 'C#',
            icon: require('../../../assets/ability/csharp.jpg')
        },
        {
            name: 'SQLserver',
            icon: require('../../../assets/ability/sqlserver.jpg')
        },
        {
            name: 'MongoDB',
            icon: require('../../../assets/ability/MongoDB.jpg')
        },

    ]


    // const isEmpty = obj => Reflect.ownKeys(obj).length === 0 && obj.constructor === Object


    return (
        <div className={styles.testBox}>
            <div className={styles.content}>
                <div className={styles.title}>个人简介</div>
                <div className={styles.info}>
                    <div className={styles.left}>
                        <img className={styles.photo} src={photo} alt="" />
                    </div>
                    <div className={styles.right}>
                        <div className={styles.item}>姓名：刘文成</div>
                        <div className={styles.item}>微信：chuqi22336</div>
                        <div className={styles.item}>电话：17601362018</div>
                        <div className={styles.item}>邮箱：lvction@126.com</div>
                        <div className={[styles.item, styles.synopsis]}>个人优势</div>
                        <div className={styles.text}>
                            <div>经验丰富，不只会前端，有8年开发经验。</div>
                            <div>有业务思维，会站在业务上考虑程序设计，注重用户体验。</div>
                            <div>热衷于学习新的技术。优秀的团队合作者，能够在多元化的环境中有效沟通，推动项目顺利进行。</div>

                            {/* <div>掌握的技能有：Photoshop、html、css、js、vue、react、uni-app、nodejs、koa、express、Electron、C#、SQLserver、MongoDB等</div> */}
                        </div>
                    </div>
                </div>
                <div className={styles.ability}>

                    <div className={styles.abilitytitle}>专业技能</div>
                    <div className={styles.abilityList}>
                        {ability.map((item, index) => (
                            <div key={index} className={styles.abilityItem}>
                                <img className={styles.icon} src={item.icon} alt="" />
                                <div className={styles.name}>{item.name}</div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}