export default class StarCanvas {
    constructor() {
        this.arr = [];
        this.colors = ["#ffff00", "#66ffff", "#3399ff", "#99ff00", "#ff9900"];
        this.timeoutList = [];
        this.canvas = document.createElement('canvas');
        document.body.appendChild(this.canvas);
        this.ctx = this.canvas.getContext("2d");
        this.resizeCanvas();
        // 给画布css样式，固定定位，且阻止用户的鼠标事件
        this.canvas.style.cssText = `
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          pointer-events: none;
        `;
    }

    resizeCanvas() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
    }
    // 封装绘制一个五角星函数
    // x是圆心横坐标，y是圆心纵坐标，其实就是鼠标位置（x ，y）
    // r是里面小圆半径 ，l是大圆半径
    // rot是初始旋转角度
    star(x, y, r, l, rot) {
        this.ctx.beginPath();
        for (let i = 0; i < 5; i++) {
            this.ctx.lineTo(
                Math.cos(((18 + i * 72 - rot) * Math.PI) / 180) * r + x,
                -Math.sin(((18 + i * 72 - rot) * Math.PI) / 180) * r + y
            );
            this.ctx.lineTo(
                Math.cos(((54 + i * 72 - rot) * Math.PI) / 180) * l + x,
                -Math.sin(((54 + i * 72 - rot) * Math.PI) / 180) * l + y
            );
        }
        this.ctx.closePath();
    }

    draw() {
        for (let i = 0; i < this.arr.length; i++) {
            let temp = this.arr[i];
            this.star(temp.x, temp.y, temp.r, temp.r * 3, temp.rot);
            this.ctx.fillStyle = temp.color;
            this.ctx.strokeStyle = temp.color;
            this.ctx.lineWidth = 0.1;
            this.ctx.lineJoin = "round";
            this.ctx.fill();
            this.ctx.stroke();
        }
    }

    update() {
        for (let i = 0; i < this.arr.length; i++) {
            let star = this.arr[i];
            star.x += star.dx;
            star.y += star.dy;
            star.rot += star.td;
            star.r -= 0.015;
            if (star.r < 0) {
                this.arr.splice(i, 1);
                // i--; // Adjust index since we removed an element
            }
        }
    }

    addStars(e) {
        // console.log(e)
        this.arr.push({
            x: e.clientX,
            y: e.clientY,
            r: Math.random() * 2 + 1.5,
            td: Math.random() * 4 - 2,
            dx: Math.random() * 2 - 1,
            dy: Math.random() * 1 + 1,
            rot: Math.random() * 90 + 90,
            color: this.colors[Math.floor(Math.random() * this.colors.length)],
        });

        for (let index = 0; index < 200; index++) {
            if (index === 0 && this.timeoutList.length > 0) {
                this.timeoutList.forEach(timeout => clearTimeout(timeout));
            }
            this.timeoutList[index] = setTimeout(() => {
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.draw();
                this.update();
            }, index * 20);
        }
    }
    clear() {
        this.arr = [];
        this.timeoutList.forEach(timeoutName => {
            clearTimeout(timeoutName)
        })
        this.timeoutList = [];
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
}
