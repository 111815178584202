import * as THREE from "three"
export class SceneObjects {
    static createTree() {
        const tree = new THREE.Group()

        // 树干
        const trunkGeometry = new THREE.CylinderBufferGeometry(5, 10, 50)
        const trunkMaterial = new THREE.MeshPhongMaterial({
            color: 0x49311c
        })
        const trunk = new THREE.Mesh(trunkGeometry, trunkMaterial)
        tree.add(trunk)

        // 树叶
        const leavesMaterial = new THREE.MeshPhongMaterial({
            color: 0x3d5e3a
        })

        // 底部树叶
        const leavesCone = new THREE.ConeBufferGeometry(20, 40, 6)
        const leavesBottom = new THREE.Mesh(leavesCone, leavesMaterial)
        leavesBottom.position.y = 35
        tree.add(leavesBottom)

        this.addRingOfLights(leavesBottom, 15, 17, -15, 0)
        this.addRingOfLights(leavesBottom, 16, 16, -15, Math.PI / 4)
        this.addRingOfLights(leavesBottom, 10, 11, -3, 0)
        this.addRingOfLights(leavesBottom, 10, 11, -3, Math.PI / 4)

        // 中部树叶
        const middleLeaveCone = new THREE.ConeBufferGeometry(15, 30, 6)
        const leavesMiddle = new THREE.Mesh(middleLeaveCone, leavesMaterial)
        leavesMiddle.position.y = 55
        tree.add(leavesMiddle)

        this.addRingOfLights(leavesMiddle, 10, 11, -8)
        this.addRingOfLights(leavesMiddle, 10, 11, -8, Math.PI / 4)

        // 顶部树叶
        const topLeaveCone = new THREE.ConeBufferGeometry(10, 20, 6)
        const leavesTop = new THREE.Mesh(topLeaveCone, leavesMaterial)
        leavesTop.position.y = 70
        tree.add(leavesTop)

        this.addRingOfLights(leavesTop, 6, 6, -3)
        this.addRingOfLights(leavesTop, 6, 6, -3, Math.PI / 4)

        return tree
    }

    static createSnowman(x, y, z) {
        const snowMaterial = new THREE.MeshPhongMaterial({
            color: 0xffffff,
            shininess: 60,
            bumpScale: 0.045,
            emissive: 0xebf7fd,
            emissiveIntensity: 0.03
        })

        // 底部雪球
        const bottomBall = new THREE.Mesh(new THREE.SphereBufferGeometry(22, 32, 32), snowMaterial)
        bottomBall.position.set(x, y, z)
        bottomBall.rotation.y = -Math.PI / 2

        // 中部雪球
        const middleBall = new THREE.Mesh(new THREE.SphereBufferGeometry(16, 32, 32), snowMaterial)
        middleBall.position.set(0, 24, 0)
        bottomBall.add(middleBall)

        // 头部
        const head = new THREE.Mesh(new THREE.SphereBufferGeometry(12, 24, 24), snowMaterial)
        head.position.y = 20
        middleBall.add(head)

        // 添加手臂、帽子等细节...
        this.addArms(middleBall)
        this.addFace(head)
        this.addHat(head)

        return bottomBall
    }

    static createCampfire() {
        const campfire = new THREE.Group()

        // 添加木头
        const logs = this.createLogs()
        campfire.add(logs)

        // 添加火焰
        const flames = this.createFlames()
        campfire.add(flames)

        return campfire
    }

    // 辅助方法
    static addRingOfLights(object, left, right, y, rotation = 0) {
        const group = new THREE.Group()
        const lightColors = ["#2980b9", "#16a085", "#d35400", "#8e44ad", "#c0392b", "#2c3e50", "#b33939", "#218c74"]

        const positions = [
            [left, y, 0],
            [-left, y, 0],
            [0, y, right],
            [0, y, -right]
        ]

        positions.forEach(pos => {
            const light = this.createChristmasLight(pos[0], pos[1], pos[2],
                this.randomFromArray(lightColors))
            group.add(light)
        })

        group.rotation.y = rotation
        object.add(group)
    }

    static createChristmasLight(x, y, z, color) {
        const bulbGeometry = new THREE.SphereBufferGeometry(1, 16, 8)
        const bulbMat = new THREE.MeshStandardMaterial({
            emissive: color || 0xffffee,
            emissiveIntensity: 3,
            color: color || 0x000000
        })
        const bulbMesh = new THREE.Mesh(bulbGeometry, bulbMat)
        bulbMesh.position.set(x, y, z)
        return bulbMesh
    }

    static randomFromArray(array) {
        return array[Math.floor(Math.random() * array.length)]
    }

    static addArms(middleBall) {
        const armMaterial = new THREE.MeshBasicMaterial({
            color: 0x111111,
            side: THREE.DoubleSide
        })

        // 右臂
        const rightBicep = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(1, 1, 22, 12, 1),
            armMaterial
        )
        rightBicep.position.set(20, 5, 0)
        rightBicep.rotation.z = Math.PI / 2
        middleBall.add(rightBicep)

        const rightForearm = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(1, 1, 15, 12, 1),
            armMaterial
        )
        rightForearm.position.set(31, 12, 0)
        rightForearm.rotation.z = Math.PI + 0.03
        middleBall.add(rightForearm)

        // 左臂
        const leftBicep = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(1, 1, 22, 12, 1),
            armMaterial
        )
        leftBicep.position.set(-20, 5, 10)
        leftBicep.rotation.z = Math.PI / 2
        leftBicep.rotation.y = Math.PI / 4
        middleBall.add(leftBicep)

        const leftForearm = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(1, 1, 15, 12, 1),
            armMaterial
        )
        leftForearm.position.set(-27, 10, 22)
        leftForearm.rotation.z = Math.PI + 0.03
        leftForearm.rotation.x = Math.PI / 4
        middleBall.add(leftForearm)

        // 添加手指
        this.addFingers(leftForearm)
    }

    static addFingers(leftForearm) {
        const fingerMaterial = new THREE.MeshBasicMaterial({
            color: 0x111111
        })

        const leftFinger = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.4, 0.4, 4, 12, 1),
            fingerMaterial
        )
        leftFinger.position.set(0, -9, 0)
        leftForearm.add(leftFinger)

        const leftLeftFinger = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.4, 0.4, 5, 12, 1),
            fingerMaterial
        )
        leftLeftFinger.position.set(2, -8, 0)
        leftLeftFinger.rotation.x = Math.PI / 8
        leftLeftFinger.rotation.z = Math.PI / 8
        leftForearm.add(leftLeftFinger)

        const leftRightFinger = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.4, 0.4, 5, 12, 1),
            fingerMaterial
        )
        leftRightFinger.position.set(-2, -8, 0)
        leftRightFinger.rotation.x = Math.PI / 8
        leftRightFinger.rotation.z = -Math.PI / 8
        leftForearm.add(leftRightFinger)
    }

    static addFace(head) {
        // 鼻子
        const noseMaterial = new THREE.MeshPhongMaterial({
            color: 0xff1133,
            shininess: 60,
            bumpScale: 0.045,
            emissive: 0xff1133,
            emissiveIntensity: 0.03
        })

        const nose = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.5, 2.5, 8, 12, 4),
            noseMaterial
        )
        nose.position.z = 15
        nose.rotation.x = 1.6
        nose.rotation.y = -1
        head.add(nose)

        // 眼睛
        const eyeMaterial = new THREE.MeshBasicMaterial({
            color: 0x000000
        })

        const leftEye = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(1.75, 1.75, 2, 12, 1),
            eyeMaterial
        )
        leftEye.rotation.x = 1.57
        leftEye.position.set(5, 3, 11)
        head.add(leftEye)

        const rightEye = leftEye.clone()
        rightEye.position.set(-5, 3, 11)
        head.add(rightEye)
    }

    static addHat(head) {
        const hatMaterial = new THREE.MeshBasicMaterial({
            color: 0x111111,
            side: THREE.DoubleSide
        })

        const hat = new THREE.Mesh(
            new THREE.CylinderGeometry(10, 10, 14, 12, 1),
            hatMaterial
        )
        hat.position.y = 12

        const brim = new THREE.Mesh(
            new THREE.RingGeometry(10, 16, 24, 1),
            hatMaterial
        )
        brim.position.y = -5
        brim.rotation.x = 1.57
        hat.add(brim)

        head.add(hat)
    }

    static createLogs() {
        const logs = new THREE.Group()
        const logGeometry = new THREE.CylinderBufferGeometry(1, 1, 6, 8)
        const logMaterial = new THREE.MeshPhongMaterial({
            color: 0x5c2626,
            shininess: 10,
            emissive: 0x5c2626
        })

        // 创建四个原木并设置位置
        const positions = [
            { x: 2, y: -3, z: 0, rotY: Math.PI / 2 },
            { x: -2, y: -3, z: 0, rotY: Math.PI / 2 },
            { x: 0, y: -2, z: 2, rotY: 0 },
            { x: 0, y: -2, z: -2, rotY: 0 }
        ]

        positions.forEach(pos => {
            const log = new THREE.Mesh(logGeometry, logMaterial)
            log.position.set(pos.x, pos.y, pos.z)
            log.rotation.z = Math.PI / 2
            log.rotation.y = pos.rotY
            logs.add(log)
        })

        return logs
    }

    static createFlames() {
        const flames = new THREE.Group()
        const flamePositions = [
            { x: 2, z: 0 },
            { x: 0, z: 2 },
            { x: 0, z: -2 },
            { x: -2, z: 0 },
            { x: 0, z: 0 }
        ]

        flamePositions.forEach((pos, index) => {
            const flame = this.createFlame(pos.x, 0, pos.z, 0xdb2902, 0xfb4402, index === 4)
            if (index === 4) {
                flame.scale.set(2, 2, 2)
            }
            flames.add(flame)
        })

        return flames
    }

    static createFlame(x, y, z, color, outerColor, hasLight = false) {
        const flame = new THREE.Group()

        if (hasLight) {
            const fireLight = this.createFireLight(color)
            flame.add(fireLight)
        }

        const geometry = new THREE.ConeGeometry(0.5, 4, 6, 10)
        const material = new THREE.MeshPhongMaterial({
            color: color,
            shininess: 550,
            emissive: color,
            transparent: true,
            opacity: 0.4
        })
        const flameMesh = new THREE.Mesh(geometry, material)
        flame.add(flameMesh)

        const outerGeometry = new THREE.ConeGeometry(1, 6, 6, 10)
        const outerMaterial = new THREE.MeshPhongMaterial({
            color: outerColor,
            shininess: 550,
            emissive: outerColor,
            transparent: true,
            opacity: 0.4
        })
        const outerFlameMesh = new THREE.Mesh(outerGeometry, outerMaterial)
        outerFlameMesh.position.y = 1
        flame.add(outerFlameMesh)

        flame.position.set(x, y, z)
        return flame
    }

    static createFireLight(color, power = 7) {
        const light = new THREE.PointLight(color || 0xffffff, 1, 0)
        light.castShadow = true
        light.shadow.mapSize.width = 512
        light.shadow.mapSize.height = 512
        light.shadow.camera.near = 0.1
        light.shadow.camera.far = 120
        light.shadow.bias = 0.9
        light.shadow.radius = 5
        light.power = power
        return light
    }

    static createChristmasText() {
        return new Promise((resolve) => {
            const loader = new THREE.FontLoader()

            loader.load('typeface.json', (font) => {

                const textGroup = new THREE.Group()

                // Merry
                const merryGeometry = new THREE.TextGeometry('Merry', {
                    font: font,
                    size: 12,
                    height: 2,
                    curveSegments: 12,
                    bevelEnabled: true,
                    bevelThickness: 0.5,
                    bevelSize: 0.3,
                    bevelOffset: 0,
                    bevelSegments: 5
                })

                // Christmas
                const christmasGeometry = new THREE.TextGeometry('Christmas', {
                    font: font,
                    size: 12,
                    height: 2,
                    curveSegments: 12,
                    bevelEnabled: true,
                    bevelThickness: 0.5,
                    bevelSize: 0.3,
                    bevelOffset: 0,
                    bevelSegments: 5
                })

                const textMaterial = new THREE.MeshPhongMaterial({
                    color: 0xd4af37,  // 金色
                    shininess: 100,
                    emissive: 0xd4af37,
                    emissiveIntensity: 0.3
                })

                const merryMesh = new THREE.Mesh(merryGeometry, textMaterial)
                const christmasMesh = new THREE.Mesh(christmasGeometry, textMaterial)

                // 计算文字宽度以居中
                merryGeometry.computeBoundingBox()
                christmasGeometry.computeBoundingBox()
                const merryWidth = merryGeometry.boundingBox.max.x - merryGeometry.boundingBox.min.x
                const christmasWidth = christmasGeometry.boundingBox.max.x - christmasGeometry.boundingBox.min.x

                // 设置位置
                merryMesh.position.set(-merryWidth / 2, 0, 0)
                christmasMesh.position.set(-christmasWidth / 2, -15, 0)  // 第二行位置略低

                textGroup.add(merryMesh)
                textGroup.add(christmasMesh)

                // 整体旋转以面向正面
                textGroup.rotation.y = Math.PI

                resolve(textGroup)
            })
        })
    }
} 