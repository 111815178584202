import React from 'react';
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useState } from 'react';
import styles from './Experience.module.less';
import Button from '@/components/Button.js';
import {
    DownOutlined,
    UpOutlined
} from '@ant-design/icons';

// 确保初始化 ScrollTrigger 插件
gsap.registerPlugin(ScrollTrigger);



const data = [{
    id: 'fb',
    name: '上海富得倍思网络科技有限公司',
    logo: require('@/assets/fudebeisiLogo.jpg'),
    time: '2022.8-2024.9',
    title: '前端组长',
    content:
        `富得倍思集团，实体门店多业态包括 面包、蛋糕、餐饮、养生按摩等。旗下有香草花园，甜心刻品牌连锁门店。
负责公司全部系统的前端建设，主要包括后台管理系统，门店收银系统APP，商城微信小程序等。
`,
}, {
    id: 'td',
    name: '上海太的信息科技有限公司',
    logo: require('@/assets/taideLogo.jpg'),
    time: '2022.2-2022.7',
    title: '前端开发',
    content: `物联网云平台服务商，主要提供环卫相关产品软硬件。
负责环卫SaaS系统,智慧市政小程序。
`,
},
{
    id: 'ws',
    name: '沃时派客（上海）包装科技有限公司',
    logo: require('@/assets/woshipaike.jpg'),
    time: '2021.3-2022.1',
    title: '前端开发',
    content: `产品外包装公司，提供包装方案设计、整合运营到包装物流，帮助企业降低包装成本。
负责后台管理系统开发、客户端小程序开发、工厂生产App开发。`,
},
{
    id: 'hy',
    name: '泓远软件（上海）有限公司',
    logo: require('@/assets/hylogo.jpg'),
    time: '2017.7-2021.3',
    title: '全栈开发',
    content: `软件公司主要从事便利店，餐饮，新零售行业的软件产品。
主要负责厦门珍熙便利店项目与上海&苏州华撷的佳思多食品料理超市项目。`,
},
{
    id: 'mj',
    name: '壹媒介数字科技集团有限公司',
    logo: require('@/assets/yimeijieLogo.jpg'),
    time: '2016.7-2017.7',
    title: '前端开发',
    content: `道闸广告公司
负责开发公司官网，TT通行门禁项目，电商偷时咖啡项目。`,
    desc: 'desc'
}]
function Experience({ message, onReceiveData }) {
    // console.log(message, 'message-')
    /*     useLayoutEffect(() => {
            let scrollTriggerInstance = gsap.to(".part2", {
                backgroundPositionY: `${-400}px`,
                ease: 'none',
                scrollTrigger: {
                    trigger: ".part2",
                    scrub: true,
                },
            })
            let scrollTriggerInstance2 = gsap.to(".part2-1", {
                backgroundPositionY: `${-400}px`,
                ease: 'none',
                scrollTrigger: {
                    trigger: ".part2-1",
                    scrub: true,
                },
            });
    
            return () => {
                if (scrollTriggerInstance && scrollTriggerInstance.scrollTrigger) {
                    scrollTriggerInstance.scrollTrigger.kill();
                    scrollTriggerInstance.kill();
                }
                if (scrollTriggerInstance2 && scrollTriggerInstance2.scrollTrigger) {
                    scrollTriggerInstance2.scrollTrigger.kill();
                    scrollTriggerInstance2.kill();
                }
            };
        }, []);  */


    const [isSpread, setIsSpread] = useState(false)
    // const visibleItems = isSpread ? data : data.filter((_, index) => index <= 2);
    function spread() {
        setIsSpread(!isSpread)
    }
    const handleSmoothScroll = (e) => {
        e.preventDefault();
        const targetId = e.currentTarget.getAttribute('href');
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    let [height, setHeight] = useState(0)
    let [normalHeight, setNormalHeight] = useState(690)

    function heightCalc() {
        setHeight(0)
        const element = document.querySelectorAll('.experienceCard');
        let totalHeight = 0;
        let tempNormalHeight = 0
        element.forEach((item, index) => {
            totalHeight += item.clientHeight + 20;
            if (index <= 2) {
                tempNormalHeight += item.clientHeight + 20;
            }
        });
        setHeight(totalHeight);
        setNormalHeight(tempNormalHeight)
    }

    useEffect(() => {
        const anchorLinks = document.querySelectorAll('a[href^="#"]');
        anchorLinks.forEach(link => {
            link.addEventListener('click', handleSmoothScroll);
        });

        heightCalc()
        window.addEventListener('resize', (e) => { heightCalc() });

        return () => {
            anchorLinks.forEach(link => {
                link.removeEventListener('click', handleSmoothScroll);
            });
            setHeight(0)
            window.removeEventListener('resize', () => { heightCalc() });

        };
    }, []);

    return (
        <div className={styles.body}>
            <div className={styles.title}>工作经历</div>
            <div className={styles.box} style={{ height: isSpread ? height + 'px' : normalHeight + 'px' }}>
                {data.map((item, index) => (
                    <div key={item.id} className={[styles.experienceCard, 'experienceCard'].join(' ')} >
                        <div className={styles.time}>{item.time}</div>
                        <div className={styles.right}>
                            <div className={styles.titleRow}>
                                <div className={styles.company}>
                                    <img className={styles.logo} src={item.logo} alt="" />
                                    {item.name}
                                </div>
                                <div className={styles.title}>{item.title}</div>
                            </div>
                            <div className={styles.content}>
                                <pre>
                                    {item.content}
                                </pre>
                            </div>
                            <div className={styles.btnBox}>
                                <a href={'#' + item.id}>
                                    <Button text='查看详情' className={index % 2 === 0 && 'btn-yellow'}></Button>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.spread} onClick={spread}>
                {isSpread ? <UpOutlined className={styles.icon} /> : <DownOutlined className={styles.icon} />}
                {isSpread ? '收起' : '查看全部'}
            </div>


        </div>


    );
}


export default Experience;