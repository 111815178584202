import './Button.less'
export default function Button({ text, className }) {
    return (

        <button className={`btn ${className}`} type="button">
            <strong>{text}</strong>
            <div className="container-stars">
                <div className={`stars ${className === 'btn-yellow' && 'stars2'}`}></div>
            </div>
        </button>

    )
}