import * as THREE from "three"

export class Controls extends THREE.EventDispatcher {
    constructor(camera, domElement) {
        super()
        this.camera = camera
        this.domElement = domElement
        this.isLocked = false
        this.minPolarAngle = 0
        this.maxPolarAngle = Math.PI
        this.pointerSpeed = 1.0
        this.lockTimer = null
        this.isLocking = false
        this.lockPromise = null

        // 常量
        this._euler = new THREE.Euler(0, 0, 0, "YXZ")
        this._vector = new THREE.Vector3()
        this._changeEvent = { type: "change" }
        this._lockEvent = { type: "lock" }
        this._unlockEvent = { type: "unlock" }
        this._PI_2 = Math.PI / 2

        // 绑定方法
        this._onMouseMove = this.onMouseMove.bind(this)
        this._onPointerlockChange = this.onPointerlockChange.bind(this)
        this._onPointerlockError = this.onPointerlockError.bind(this)

        this.connect()
    }

    connect() {
        this.domElement.ownerDocument.addEventListener("mousemove", this._onMouseMove)
        this.domElement.ownerDocument.addEventListener("pointerlockchange", this._onPointerlockChange)
        this.domElement.ownerDocument.addEventListener("pointerlockerror", this._onPointerlockError)
    }

    disconnect() {
        this.domElement.ownerDocument.removeEventListener("mousemove", this._onMouseMove)
        this.domElement.ownerDocument.removeEventListener("pointerlockchange", this._onPointerlockChange)
        this.domElement.ownerDocument.removeEventListener("pointerlockerror", this._onPointerlockError)
    }

    dispose() {
        this.disconnect()
        this.isLocking = false
        if (this.lockTimer) {
            clearTimeout(this.lockTimer)
            this.lockTimer = null
        }
        if (this.lockPromise) {
            this.lockPromise = null
        }
    }

    getObject() {
        return this.camera
    }

    getDirection(v) {
        return v.set(0, 0, -1).applyQuaternion(this.camera.quaternion)
    }

    moveForward(distance) {
        this._vector.setFromMatrixColumn(this.camera.matrix, 0)
        this._vector.crossVectors(this.camera.up, this._vector)
        this.camera.position.addScaledVector(this._vector, distance)
    }

    moveRight(distance) {
        this._vector.setFromMatrixColumn(this.camera.matrix, 0)
        this.camera.position.addScaledVector(this._vector, distance)
    }

    async lock() {
        if (this.isLocking) {
            return
        }

        if (this.lockTimer) {
            clearTimeout(this.lockTimer)
            this.lockTimer = null
        }

        this.isLocking = true

        try {
            if (this.lockPromise) {
                await this.lockPromise
            }

            await new Promise((resolve, reject) => {
                this.lockTimer = setTimeout(async () => {
                    try {
                        this.lockPromise = this.domElement.requestPointerLock()
                        await this.lockPromise
                        resolve()
                    } catch (error) {
                        reject(error)
                    } finally {
                        this.lockPromise = null
                        this.lockTimer = null
                    }
                }, 200)
            })
        } catch (error) {
            console.warn("Pointer lock request failed:", error)
        } finally {
            this.isLocking = false
        }
    }

    unlock() {
        this.isLocking = false
        if (this.lockTimer) {
            clearTimeout(this.lockTimer)
            this.lockTimer = null
        }
        if (this.lockPromise) {
            this.lockPromise = null
        }
        if (document.exitPointerLock) {
            document.exitPointerLock()
        }
    }

    onMouseMove(event) {
        if (this.isLocked === false) return

        const movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0
        const movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0

        this._euler.setFromQuaternion(this.camera.quaternion)
        this._euler.y -= movementX * 0.002 * this.pointerSpeed
        this._euler.x -= movementY * 0.002 * this.pointerSpeed
        this._euler.x = Math.max(this._PI_2 - this.maxPolarAngle,
            Math.min(this._PI_2 - this.minPolarAngle, this._euler.x))

        this.camera.quaternion.setFromEuler(this._euler)
        this.dispatchEvent(this._changeEvent)
    }

    onPointerlockChange() {
        if (this.domElement.ownerDocument.pointerLockElement === this.domElement) {
            this.dispatchEvent(this._lockEvent)
            this.isLocked = true
        } else {
            this.dispatchEvent(this._unlockEvent)
            this.isLocked = false
            this.isLocking = false
            if (this.lockPromise) {
                this.lockPromise = null
            }
        }
    }

    onPointerlockError() {
        console.warn("THREE.PointerLockControls: Unable to use Pointer Lock API")
        this.isLocking = false
        if (this.lockTimer) {
            clearTimeout(this.lockTimer)
            this.lockTimer = null
        }
        if (this.lockPromise) {
            this.lockPromise = null
        }
    }
} 