// import logo from './assets/logo.svg'
import Home from '@/pages/Home/Home.js';
import Christmas from '@/pages/Christmas/index.js';
import AnimationCard from '@/pages/AnimationCard/AnimationCard.js';

import Brand from '@/pages/Brand/Brand.js';
import zhCN from 'antd/es/locale/zh_CN';


import { ConfigProvider } from 'antd';
import { BrowserRouter, Routes, Route } from "react-router-dom"


function App() {


  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          // Seed Token，影响范围大
          colorPrimary: '#7cb305'
        },
        components: {
          Anchor: {
            colorPrimary: '#fff',
            colorText: '#fff',
            linkPaddingBlock: 10,
            linkPaddingInlineStart: 20
            /* 这里是你的组件 token */
          },
          Image: {
            previewOperationColor: 'rgba(255, 255, 255, 0.65)',
          }
        },
      }}

    >
      {/* 路由器 */}
      <BrowserRouter>
        {/* 路由映射列表 */}
        <Routes>
          {/* 路由具体路径匹配 */}
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/Brand" element={<Brand></Brand>}></Route>
          <Route path="/Christmas" element={<Christmas></Christmas>}></Route>
          <Route path="/AnimationCard" element={<AnimationCard></AnimationCard>}></Route>
          {/*          <Route path="/forget" element={<ForgetPassword></ForgetPassword>}></Route> */}
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
